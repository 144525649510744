import {
  Button,
  useDisclosure,
  Badge,
  IconButton,
  VStack,
  HStack,
  Select,
  Tag,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import {
  Buildings,
  Check,
  CreditCard,
  EnvelopeSimple,
  EnvelopeSimpleOpen,
  PencilSimple,
  TrashSimple,
  UserCircle,
} from "phosphor-react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import useOrganisationsApi from "../../../../../Api/Resources/Organisations/OrganisationsApi";
import useOrganisationMutation from "../../../../../Api/Resources/Organisations/OrganisationsMutation";
import useTeamsApi from "../../../../../Api/Resources/Teams/TeamsApi";
import { Card, CardHeader } from "../../../../../UIKit/Card/Card";
import { InviteUserModal } from "../Components/InviteUserModal";
import { ModifyOrganisationModal } from "../Components/ModifyOrganisationModal";
import { ModifyTeamModal } from "../Components/ModifyTeamModal";
import { formatDistanceToNow } from "date-fns";
import CustomTable, {
  TableColumn,
  TableRow,
} from "../../../../../UIKit/Table/Table";
import { StandardTableRow } from "../../../../../UIKit/Table/StandardTableRow";
import TitledPage from "../../../../../Components/TitledPage";
import { UserHighlight } from "../../../../../Components/UserHighlight";
import { useEffect, useState } from "react";
import produce from "immer";
import {
  OrganisationUser,
  User,
} from "../../../../../Api/Resources/Users/UsersApiTypes";
import { useAuthStore } from "../../../../../Store/AuthStore";
import shallow from "zustand/shallow";
import { usePermissions } from "../../../../../Store/PermissionStore";
import { PlansDialog } from "../../../../../UIKit/PlansDialog/PlansDialog";

const pricingPlans = [
  {
    name: "Team",
    priceMonthly: "£11/seat/mo",
    priceYearly: "£30/seat/mo",
    features: {
      "Team Members": "30 members",
      "Team Management": "✓",
      Entries: "Unlimited",
      "Projects & Categories": "Unlimited",
      Customization: "✓",
    },
  },
  {
    name: "Enterprise",
    priceMonthly: "£40/seat/mo",
    priceYearly: "£80/seat/mo",
    features: {
      "Team Members": "100 members",
      "Team Management": "✓",
      Entries: "Unlimited",
      "Projects & Categories": "Unlimited",
      Customization: "✓",
    },
  },
];

const ViewOrganisationPage = () => {
  const { authTokens } = useAuthStore(
    (state) => ({ authTokens: state.authTokens }),
    shallow
  );

  const userId = authTokens?.userId;

  const [isEditing, setIsEditing] = useState(false);
  const [uneditedData, setUneditedData] = useState<TableRow[]>([]);
  const [data, setData] = useState<TableRow[]>([]);
  const getPermissions = usePermissions();

  const { organisationId } = useParams();
  const organisationModal = useDisclosure();
  const inviteUserModal = useDisclosure();
  const teamModal = useDisclosure();

  const organisationsApi = useOrganisationsApi();
  const teamsApi = useTeamsApi();
  const { resendInvite, deleteInvite, removeUser } = useOrganisationMutation();

  const { data: organisation, isFetching: isFetchingOrganisation } = useQuery(
    ["organisation", organisationId],
    () => {
      if (organisationId) {
        return organisationsApi.get(organisationId);
      }

      return;
    }
  );

  const { data: members, isFetching: isFetchingMembers } = useQuery(
    ["organisationMembers", organisationId],
    () => {
      if (organisationId) {
        return organisationsApi.listOrganisationMembers(organisationId);
      }

      return;
    }
  );

  const { data: invites, isFetching: isFetchingInvites } = useQuery(
    ["organisationInvitations", organisationId],
    () => {
      if (organisationId) {
        return organisationsApi.listInvites(organisationId);
      }

      return;
    }
  );

  const { data: teams, isFetching: isFetchingTeams } = useQuery(
    ["teams", organisationId],
    () => {
      if (organisationId) {
        return teamsApi.listForOrganisation(organisationId);
      }

      return;
    }
  );

  const onResend = (inviteId: string) => {
    resendInvite.mutate({
      organisationId: organisationId!,
      inviteId,
    });
  };

  const onDeleteInvite = (inviteId: string) => {
    deleteInvite.mutate({
      organisationId: organisationId!,
      inviteId,
    });
  };

  const removeUserFromOrg = (index: number) => {
    const updatedData = produce(data, (draft) => {
      draft.splice(index, 1);
    });

    setData(updatedData);
  };

  const onSave = () => {
    // Compare data and uneditedData to work out what items have been removed.
    // Then call removeUser for each of those items.
    const removedItems =
      uneditedData
        .filter((item) => !data.find((d) => d.id === item.id))
        .map((item) => item.id as string) ?? [];

    organisationsApi.removeUsers({
      userIds: removedItems,
      organisationId: organisationId!,
    });

    // Compare data and uneditedData to work out what items have been changed.
    const changedItems = data
      .map((item) => {
        return item as OrganisationUser;
      })
      .filter((item) => {
        const uneditedItem = uneditedData.find(
          (d) => d.id === item.id
        ) as OrganisationUser;
        return uneditedItem.role !== item.role;
      });

    organisationsApi.updateUsers({
      users: changedItems,
      organisationId: organisationId!,
    });

    setIsEditing(false);
  };

  const onCancel = () => {
    setData(uneditedData);
    setIsEditing(false);
  };

  useEffect(() => {
    setData(members ?? []);
  }, [members]);

  const onEdit = () => {
    setUneditedData(data);
    setIsEditing(true);
  };

  const onRoleChange = (value: string, userId: string) => {
    const updatedData = produce(data, (draft) => {
      const user = draft.find((item) => item.id === userId) as OrganisationUser;
      user.role = value;
    });

    setData(updatedData);
  };

  let columns: TableColumn[] = [
    { label: "Name", accessor: "icon" },
    { label: "Last Seen", accessor: "lastSeen" },
    { label: "Role", accessor: "role" },
  ];

  if (isEditing) {
    columns = [
      ...columns,
      { label: "Remove", accessor: "remove", isNumeric: true },
    ];
  }

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <TitledPage
      isLoading={isFetchingOrganisation}
      title={organisation?.name ?? ""}
      breadcrumbs={[
        {
          label: "Account",
          link: "/account",
        },
        {
          label: "Organisations",
          link: "/account/organisations",
        },
      ]}
      action={
        getPermissions("edit_organisation", organisationId!)
          ? {
              label: "Edit Organisation",
              icon: PencilSimple,
              trigger: organisationModal.onOpen,
            }
          : undefined
      }
      placeholderIcon={Buildings}
      placeholderMessage={"No organisations."}
      showPlaceholder={!organisation}
    >
      <ModifyOrganisationModal
        organisation={organisation}
        isOpen={organisationModal.isOpen}
        onClose={organisationModal.onClose}
      />

      <InviteUserModal
        organisationId={organisation?.id}
        isOpen={inviteUserModal.isOpen}
        onClose={inviteUserModal.onClose}
      />

      <ModifyTeamModal
        organisationId={organisation?.id!}
        isOpen={teamModal.isOpen}
        onClose={teamModal.onClose}
      />

      <VStack
        marginTop={"16px"}
        marginBottom={"64px"}
        spacing={4}
        flexDirection={"column"}
      >
        <Card>
          <CardHeader
            isStandalone={true}
            title="Plan & Billing"
            subtitle={"The plan and billing information for this organisation."}
            action={
              <>
                {getPermissions(
                  "change_organisation_plan",
                  organisationId!
                ) && (
                  <Flex alignItems={"center"} gap={8}>
                    <Text fontWeight={"medium"} size={"sm"}>
                      Daybreak Team
                    </Text>
                    <PlansDialog
                      highlightPlan={"Team"}
                      plans={pricingPlans}
                      isOpen={isOpen}
                      onClose={onClose}
                    >
                      <Button
                        leftIcon={<CreditCard weight="bold" />}
                        variant={"outline"}
                        colorScheme={"blue"}
                        onClick={onOpen}
                      >
                        Change Plan
                      </Button>
                    </PlansDialog>
                  </Flex>
                )}
              </>
            }
          />
        </Card>
        <Card>
          <CardHeader
            title={`Members`}
            subtitle={"View and edit organisation members."}
            action={
              !isEditing ? (
                <HStack>
                  {getPermissions(
                    "edit_organisation_members",
                    organisationId!
                  ) && (
                    <Button
                      leftIcon={<PencilSimple weight="bold" />}
                      variant={"outline"}
                      colorScheme={"blue"}
                      onClick={onEdit}
                    >
                      Edit Members
                    </Button>
                  )}
                </HStack>
              ) : (
                <HStack>
                  <Button variant={"outline"} onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    leftIcon={<Check weight="bold" />}
                    colorScheme={"green"}
                    onClick={onSave}
                  >
                    Save Members
                  </Button>
                </HStack>
              )
            }
          />

          <CustomTable
            placeholder={{
              label: "No users",
              icon: UserCircle,
            }}
            renderRow={(options) => {
              return <StandardTableRow {...options} />;
            }}
            rows={
              data?.map((item, index) => {
                const user = item as OrganisationUser;

                return {
                  icon: <UserHighlight user={user} />,
                  name: user.displayName,
                  lastSeen:
                    user?.lastSeen &&
                    formatDistanceToNow(new Date(user?.lastSeen), {
                      addSuffix: true,
                    }),
                  role: isEditing ? (
                    <Select
                      value={user.role}
                      onChange={(e) => onRoleChange(e.target.value, user.id)}
                      isDisabled={user.id === userId}
                    >
                      <option value="admin">Admin</option>
                      <option value="manager">Manager</option>
                      <option value="user">User</option>
                    </Select>
                  ) : (
                    <Badge px={2} py={1}>
                      {user.role}
                    </Badge>
                  ),
                  remove: (
                    <IconButton
                      onClick={() => removeUserFromOrg(index)}
                      isDisabled={user.id === userId}
                      aria-label="Remove User"
                      icon={<TrashSimple weight="bold" />}
                    />
                  ),
                };
              }) ?? []
            }
            columns={columns}
          />
        </Card>
        {getPermissions("invite_organisation_members", organisationId!) && (
          <Card>
            <CardHeader
              title="Invitations"
              subtitle={"View and send new invitations."}
              action={
                <Button
                  variant={"outline"}
                  leftIcon={<EnvelopeSimpleOpen weight="bold" />}
                  colorScheme="blue"
                  onClick={inviteUserModal.onOpen}
                >
                  Send Invite
                </Button>
              }
            />

            <CustomTable
              placeholder={{
                label: "No active invitations",
                icon: EnvelopeSimple,
              }}
              renderRow={(options) => {
                return <StandardTableRow {...options} />;
              }}
              rows={
                invites?.map((invite) => {
                  return {
                    email: invite.email,
                    ignored: invite.ignored ? "Yes" : "No",
                    resend: (
                      <Button
                        onClick={() => onResend(invite.id)}
                        isDisabled={!invite.ignored}
                      >
                        Resend
                      </Button>
                    ),
                    delete: (
                      <Button onClick={() => onDeleteInvite(invite.id)}>
                        Delete
                      </Button>
                    ),
                  };
                }) ?? []
              }
              columns={[
                { label: "Email", accessor: "email" },
                { label: "Ignored", accessor: "ignored" },
                { label: "Resend", accessor: "resend" },
                { label: "Delete", accessor: "delete" },
              ]}
            />
          </Card>
        )}
      </VStack>
    </TitledPage>
  );
};

export default ViewOrganisationPage;
