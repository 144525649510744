import { Flex } from "@chakra-ui/react";
import { CaretRight } from "phosphor-react";
import ResponsiveContainer from "../../../../../UIKit/ResponsiveContainer/ResponsiveContainer";
import styles from "./TeamListItem.module.scss";
import { Item } from "../../../../../UIKit/ListItem/ListItem";
import { Team } from "../../../../../Api/Resources/Teams/TeamsApiTypes";

export const TeamListItem = ({
  item,
  onClick,
}: {
  item: Item;
  onClick?: (id: string) => void;
}) => {
  const team: Team = item.data;
  const organisation = team.organisation;

  return (
    <Flex
      onClick={() => onClick && team.id && onClick(team.id)}
      className={styles.listItem}
    >
      <ResponsiveContainer>
        <Flex alignItems={"center"}>
          <Flex className={styles.container}>
            <Flex className={styles.content}>
              <Flex className={styles.name}>{item.label}</Flex>
              <Flex className={styles.description}>{item.description}</Flex>
            </Flex>
          </Flex>
          <CaretRight weight="bold" size={24} />
        </Flex>
      </ResponsiveContainer>
    </Flex>
  );
};
