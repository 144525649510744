import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import ResponsiveContainer from "../ResponsiveContainer/ResponsiveContainer";
import styles from "./Navbar.module.scss";
import { NavbarItemType } from "./Types/NavbarItemType";
import PageHeader from "../PageHeader/PageHeader";
import { NavLink } from "react-router-dom";
import { PageAction, BreadcrumbDataItem } from "../../Store/AppStore";
import shallow from "zustand/shallow";
import { Platform, usePlatformStore } from "../../Store/PlatformStore";
import { useMemo } from "react";
import DraftNavbar from "./DraftNavbar";

export const NavbarItem = ({ item }: { item: NavbarItemType }) => {
  return (
    <NavLink
      end={item.route === "/"}
      style={({ isActive }) =>
        isActive
          ? {
              color: "#FFA818",
            }
          : undefined
      }
      className={styles.navbarItem}
      to={item.route}
      children={({ isActive }) => {
        return (
          <>
            {item.label}
            <Box
              className={`${styles.navbarItemBorder} ${
                isActive ? styles.active : ""
              }`}
            />
          </>
        );
      }}
    />
  );
};

const Action = (props: { action?: PageAction | PageAction[] }) => {
  const { action } = props;

  if (!action) {
    return null;
  }

  let actions;

  if ((action as any).label) {
    actions = [action as PageAction];
  } else {
    actions = action as PageAction[];
  }

  return (
    <HStack>
      {actions.map((pageAction, index) => {
        const isFirst = index === 0;
        const Icon = pageAction.icon;

        if (!pageAction.label && Icon) {
          return (
            <IconButton
              key={index}
              variant={"outline"}
              colorScheme="blue"
              aria-label={pageAction.helpText ?? ""}
              icon={<Icon size={22} />}
              onClick={pageAction.trigger}
            />
          );
        }

        return (
          <Button
            key={index}
            rightIcon={<Icon />}
            variant={isFirst && actions.length > 1 ? "ghost" : "solid"}
            colorScheme="blue"
            onClick={pageAction.trigger}
          >
            {pageAction.label}
          </Button>
        );
      })}
    </HStack>
  );
};

export const Navbar = ({
  items,
  title,
  subtitle,
  isLoadingTitle = false,
  action,
  breadcrumbs,
  isLoggedIn,
  showPageHeader,
  isFloating = false,
}: {
  items: NavbarItemType[];
  title?: string;
  subtitle?: string;
  action?: PageAction | PageAction[];
  breadcrumbs?: BreadcrumbDataItem[];
  isLoggedIn: boolean;
  showPageHeader: boolean;
  isFloating?: boolean;
  isLoadingTitle?: boolean;
}) => {
  const { platform } = usePlatformStore(
    (state) => ({ platform: state.platform }),
    shallow
  );

  const isMobile = useMemo(() => {
    return platform === Platform.Mobile;
  }, [platform]);

  return (
    <Flex
      className={`${styles.navbar} ${isFloating ? styles.floating : ""} ${
        !isLoggedIn ? styles.unauthenticated : ""
      }`}
    >
      <ResponsiveContainer>
        <DraftNavbar isLoggedIn={isLoggedIn} items={items} />

        {title && (
          <Flex flexDirection={"column"}>
            {showPageHeader && breadcrumbs && (
              <Breadcrumb fontWeight="medium" fontSize="sm" marginTop={"24px"}>
                {breadcrumbs.map((breadcrumb, index) => {
                  return (
                    <BreadcrumbItem key={index}>
                      <BreadcrumbLink href={breadcrumb.link}>
                        {breadcrumb.label}
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                  );
                })}
              </Breadcrumb>
            )}

            {showPageHeader && (
              <Flex
                marginTop={!breadcrumbs ? "24px" : 0}
                marginBottom={"8px"}
                alignItems={"flex-end"}
                justifyContent={"space-between"}
              >
                <PageHeader
                  isLoading={isLoadingTitle}
                  title={title}
                  subtitle={subtitle}
                />
                <Box marginBottom={"12px"}>
                  {!isMobile && <Action action={action} />}
                </Box>
              </Flex>
            )}
          </Flex>
        )}
      </ResponsiveContainer>
    </Flex>
  );
};
