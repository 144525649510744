import create from "zustand";
import { devtools, persist } from "zustand/middleware";

export type AuthTokens = {
  accessToken: string;
  refreshToken: string;
  userId: string;
};

export interface AuthState {
  authTokens?: AuthTokens;
  setAuthTokens: (authTokens: AuthTokens) => void;
  clearAuthTokens: () => void;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        setAuthTokens: (authTokens: AuthTokens) =>
          set(() => ({
            authTokens,
          })),
        clearAuthTokens: () =>
          set(() => ({
            authTokens: undefined,
          })),
      }),
      {
        name: "auth-state",
      }
    )
  )
);

export const useUserId = () => {
  const authState = useAuthStore();
  return authState.authTokens?.userId;
};
