import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import TitledPage from "../../../Components/TitledPage";
import useUsersApi from "../../../Api/Resources/Users/UsersApi";
import { formatDistanceToNow } from "date-fns";
import { Eye } from "phosphor-react";
import UserEntriesTab from "./Components/UserEntriesTab";
import UserInsightsTab from "./Components/UserInsightsTab";

const ViewUsersPage = () => {
  const { personId: userId } = useParams();
  const usersApi = useUsersApi();

  const { data: user, isFetching: isFetchingUser } = useQuery(
    ["user", userId],
    () => {
      return usersApi.get(userId!);
    }
  );

  return (
    <TitledPage isLoading={isFetchingUser} title={user?.displayName ?? ""}>
      <Tabs marginTop={"12px"} isLazy>
        <TabList>
          <Tab>Insights</Tab>
          <Tab>Entries</Tab>
        </TabList>
        <TabPanels>
          <TabPanel paddingX={0}>
            <UserInsightsTab />
          </TabPanel>
          <TabPanel paddingX={0}>
            <UserEntriesTab subtitle="View entries for this user." />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </TitledPage>
  );
};

export default ViewUsersPage;
