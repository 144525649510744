import useMethods from "../../useMethods";
import { ChargeRate, ChargeRateType } from "../Projects/ProjectsApiTypes";

export type ChargeRateCreateBody = {
  items: {
    value: string;
    type: ChargeRateType;
    userId: string;
    projectId: string;
  }[];
};

const useChargeRatesApi = () => {
  const methods = useMethods();

  const listForProjects = async (projectId: string): Promise<ChargeRate[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/charge-rates/projects/${projectId}`
    );
    return result.data;
  };

  const upsert = async (
    chargeRateCreateBody: ChargeRateCreateBody
  ): Promise<ChargeRate[]> => {
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/charge-rates/bulk`,
      chargeRateCreateBody
    );
    return result.data;
  };

  return {
    listForProjects,
    upsert,
  };
};

export default useChargeRatesApi;
