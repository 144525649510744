import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Article, Clock } from "phosphor-react";
import shallow from "zustand/shallow";
import { Platform, usePlatformStore } from "../../../../../Store/PlatformStore";
import { useSettingsStore } from "../../../../../Store/SettingsStore";
import { formatTime } from "../../../../../Utilities/TimeUtility";
import styles from "./EntryStatsModal.module.scss";

export const EntryStatsModal = ({
  isOpen,
  onClose,
  totalTime,
  totalEntries,
}: {
  isOpen: boolean;
  onClose: () => void;
  totalTime?: number;
  totalEntries?: number;
}) => {
  const { platform } = usePlatformStore(
    (state) => ({ platform: state.platform }),
    shallow
  );

  const { showSeconds } = useSettingsStore(
    (state) => ({
      showSeconds: state.showSeconds,
    }),
    shallow
  );
  
  return (
    <>
      <Modal
        size={platform === Platform.Mobile ? "full" : "lg"}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={2}>
              <Flex
                width={"100%"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Flex className={styles.subtitle}>Total Hours</Flex>
                <Flex alignItems={"center"} className={styles.time}>
                  <Clock weight="fill" />
                  <Text marginLeft={"8px"}>{formatTime(totalTime ?? 0, showSeconds)}</Text>
                </Flex>
              </Flex>
              <Flex
                width={"100%"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Flex className={styles.subtitle}>Total Entries</Flex>
                <Flex alignItems={"center"} className={styles.time}>
                  <Article weight="fill" />
                  <Text marginLeft={"8px"}>{totalEntries ?? 0}</Text>
                </Flex>
              </Flex>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};
