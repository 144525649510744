import { BreadcrumbDataItem, PageAction, useAppStore } from "../Store/AppStore";
import Page from "../UIKit/Page/Page";
import shallow from "zustand/shallow";
import { useEffect } from "react";
import Placeholder from "../UIKit/Placeholder/Placeholder";
import { IconProps } from "phosphor-react";

const TitledPage = ({
  children,
  title,
  subtitle,
  action,
  isResponsive = true,
  breadcrumbs,
  isLoading,
  isLoadingTitle,
  showPlaceholder = false,
  placeholderIcon,
  placeholderMessage,
}: {
  children?: JSX.Element[] | JSX.Element | string;
  title: string;
  subtitle?: string;
  action?: PageAction | PageAction[];
  breadcrumbs?: BreadcrumbDataItem[];
  isResponsive?: boolean;
  isLoading?: boolean;
  isLoadingTitle?: boolean;
  showPlaceholder?: boolean;
  placeholderMessage?: string;
  placeholderIcon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
}) => {
  const { setPageData } = useAppStore(
    (state) => ({ setPageData: state.setPageData }),
    shallow
  );

  useEffect(() => {
    document.title = `Daybreak | ${title}`;

    setPageData({
      title,
      subtitle,
      action,
      breadcrumbs,
      isLoadingTitle,
    });
  }, [title, subtitle, action, isLoadingTitle, breadcrumbs]);

  return (
    <Page isLoading={isLoading} isResponsive={isResponsive}>
      <>
        {showPlaceholder && (
          <Placeholder icon={placeholderIcon!} message={placeholderMessage!} />
        )}
        {children ? children : <></>}
      </>
    </Page>
  );
};

export default TitledPage;
