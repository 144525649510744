import { useQuery } from "react-query";
import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Td,
  VStack,
} from "@chakra-ui/react";
import { Check, PencilSimple, Placeholder } from "phosphor-react";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import { useParams } from "react-router-dom";
import CustomTable, {
  ColumnType,
  TableRow,
} from "../../../../UIKit/Table/Table";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";
import { useEffect, useState } from "react";
import {
  ChargeRate,
  ChargeRateType,
} from "../../../../Api/Resources/Projects/ProjectsApiTypes";
import produce from "immer";
import useChargeRatesApi, {
  ChargeRateCreateBody,
} from "../../../../Api/Resources/ChargeRates/ChargeRatesApi";
import useProjectsApi from "../../../../Api/Resources/Projects/ProjectsApi";
import { groupBy } from "../../../../Utilities/GroupBy";
import { first } from "../../../../Utilities/First";
import { UserTableRow } from "../../../../UIKit/Table/UserTableRow";

const BillingTab = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { projectId } = useParams();
  const chargeRatesApi = useChargeRatesApi();
  const projectsApi = useProjectsApi();

  const [uneditedData, setUneditedData] = useState<TableRow[]>([]);
  const [data, setData] = useState<TableRow[]>([]);

  const { data: users, isFetching: isFetchingUsers } = useQuery(
    ["users", projectId],
    () => {
      if (projectId) {
        return projectsApi.listUsers(projectId);
      }

      return;
    }
  );

  const { data: chargeRates, isFetching: isFetchingChargeRates } = useQuery(
    ["chargeRates", projectId],
    () => {
      if (projectId) {
        return chargeRatesApi.listForProjects(projectId);
      }

      return;
    }
  );

  useEffect(() => {
    if (users && chargeRates) {
      const chargeRatesByUser = groupBy(
        chargeRates,
        (item) => item.userId,
        "default"
      );

      const data = users.map((user) => {
        const chargeRate = first(chargeRatesByUser[user.id]);

        return {
          userId: user.id,
          id: chargeRate?.id,
          user,
          chargeRate,
        };
      });

      setData(data);
    }
  }, [chargeRates, users]);

  const onChange = (value: string, rowIndex: number) => {
    const updatedData = produce(data, (draft) => {
      const item = draft[rowIndex].chargeRate as ChargeRate;

      draft[rowIndex].chargeRate = {
        ...item,
        value,
        type: ChargeRateType.Hour,
      };
    });

    setData(updatedData);
  };

  const onCancel = () => {
    setData(uneditedData);
    setIsEditing(false);
  };

  const onEdit = () => {
    setUneditedData(data);
    setIsEditing(true);
  };

  const onSave = () => {
    // Create the submission of charge rates...
    const body: ChargeRateCreateBody = {
      items: [],
    };

    data.forEach((item) => {
      const chargeRate = item.chargeRate as ChargeRate;
      const userId = item.userId as string;

      if (chargeRate) {
        body.items.push({
          ...chargeRate,
          projectId: projectId!,
          userId: userId!,
        });
      }
    });

    // Upsert the charge rates...
    chargeRatesApi.upsert(body);
    setIsEditing(false);
  };

  return (
    <VStack spacing={4}>
      <Card isPadded={false}>
        <CardHeader
          title="Charge Rates"
          subtitle="Manage charge rates for members of this project."
          action={
            !isEditing ? (
              <HStack>
                <Button
                  leftIcon={<PencilSimple weight="bold" />}
                  variant={"outline"}
                  colorScheme={"blue"}
                  onClick={onEdit}
                >
                  Edit Charge Rates
                </Button>
              </HStack>
            ) : (
              <HStack>
                <Button variant={"outline"} onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  leftIcon={<Check weight="bold" />}
                  colorScheme={"green"}
                  onClick={onSave}
                >
                  Save Charge Rates
                </Button>
              </HStack>
            )
          }
        />

        <CustomTable
          isLoading={isFetchingChargeRates}
          placeholder={{
            label: "No billing",
            icon: Placeholder,
          }}
          renderRow={(options) => {
            const {
              row,
              column: { type, isDisabled, accessor },
              rowIndex,
              totalRows,
            } = options;

            switch (type) {
              case ColumnType.INPUT: {
                const item = row[accessor] as {
                  value: string;
                  type: ChargeRateType;
                };

                return (
                  <Td borderBottom={rowIndex === totalRows - 1 ? 0 : undefined}>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color="gray.300"
                        fontSize="1.2em"
                        children="$"
                      />
                      <Input
                        onChange={(e) => onChange(e.target.value, rowIndex)}
                        isDisabled={!isEditing || isDisabled}
                        value={item?.value}
                      />
                      <InputRightAddon children={item?.type ?? "hour"} />
                    </InputGroup>
                  </Td>
                );
              }
              case ColumnType.USER: {
                return <UserTableRow {...options} />;
              }
              default: {
                return <StandardTableRow {...options} />;
              }
            }
          }}
          rows={data}
          columns={[
            { label: "Name", accessor: "user", type: ColumnType.USER },
            {
              label: "Project Rate",
              accessor: "chargeRate",
              type: ColumnType.INPUT,
            },
          ]}
        />
      </Card>
    </VStack>
  );
};

export default BillingTab;
