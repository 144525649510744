import {
  CreateChargeRateRequest,
  CreateProjectRequest,
  UpdateProjectRequest,
} from "./ProjectsApiTypes";
import ProjectsApi from "./ProjectsApi";
import { useMutation, useQueryClient } from "react-query";

const useProjectMutation = () => {
  const projectsApi = ProjectsApi();
  const queryClient = useQueryClient();

  const updateProject = useMutation({
    mutationFn: (project: UpdateProjectRequest) => {
      return projectsApi.update(project);
    },
    onSuccess: (data, variables) => {
      const { id } = variables;
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.invalidateQueries({ queryKey: ["project", id] });
    },
  });

  const createProject = useMutation({
    mutationFn: (project: CreateProjectRequest) => {
      return projectsApi.create(project);
    },
    onSuccess: (data, variables) => {
      const { teamId } = variables;

      queryClient.invalidateQueries({ queryKey: ["projects"] });

      if (teamId) {
        queryClient.invalidateQueries({ queryKey: ["teamProjects", teamId] });
      }
    },
  });

  const deleteProject = useMutation({
    mutationFn: (options: { projectId: string; teamId?: string }) => {
      const { projectId } = options;
      return projectsApi.deleteOne(projectId);
    },
    onSuccess: (data, variables) => {
      const { projectId, teamId } = variables;
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.invalidateQueries({ queryKey: ["project", projectId] });

      if (teamId) {
        queryClient.invalidateQueries({ queryKey: ["teamProjects", teamId] });
        queryClient.invalidateQueries({ queryKey: ["teams", teamId] });
      }
    },
  });

  return {
    createProject,
    updateProject,
    deleteProject,
  };
};

export default useProjectMutation;
