import { Button, useDisclosure } from "@chakra-ui/react";
import { Plus, TagChevron } from "phosphor-react";
import { useMemo, useState } from "react";
import TitledPage from "../../Components/TitledPage";
import { useQuery } from "react-query";
import Placeholder from "../../UIKit/Placeholder/Placeholder";
import ListContainer from "../../UIKit/List/List";
import { useParams } from "react-router-dom";
import { ModifyCategoryModal } from "../Projects/View/Components/ModifyCategoryModal";
import { CategoriesListItem } from "./Components/CategoriesListItem";
import useCategoriesApi from "../../Api/Resources/Categories/CategoriesApi";
import { Category } from "../../Api/Resources/Categories/CategoriesApiTypes";
import useProjectsApi from "../../Api/Resources/Projects/ProjectsApi";

const ListCategoriesPage = () => {
  const { projectId } = useParams();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { list: listCategories } = useCategoriesApi();
  const { get: getProject } = useProjectsApi();

  const { data: _categories, isFetching: isFetchingCategories } = useQuery(
    ["categories", projectId],
    () => {
      if (projectId) {
        return listCategories(projectId);
      }
    }
  );

  const { data: _project, isFetching: isFetchingProject } = useQuery(
    ["projects", projectId],
    () => {
      if (projectId) {
        return getProject(projectId);
      }
    }
  );

  const onItemSelected = (index: number) => {
    if (_categories) {
      const category = _categories[index];
      setSelectedCategory(category);
      onOpen();
    }
  };

  const onPrepareModalClose = () => {
    setSelectedCategory(undefined);
    onClose();
  };

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: "Projects",
        link: "/projects",
      },
      {
        label: _project?.name ?? "",
        link: `/projects/${_project?.id}`,
      },
    ];
  }, [_project]);

  return (
    <TitledPage
      isResponsive={false}
      title={"Categories"}
      breadcrumbs={breadcrumbs}
      action={{
        label: "Create Category",
        icon: Plus,
        trigger: onOpen,
      }}
      showPlaceholder={_categories?.length === 0}
      placeholderIcon={TagChevron}
      placeholderMessage={"No categories"}
    >
      <ModifyCategoryModal
        projectId={projectId!}
        category={selectedCategory}
        isOpen={isOpen}
        onClose={onPrepareModalClose}
      />

      <ListContainer>
        {(_categories ?? []).map((item, index) => {
          return (
            <CategoriesListItem
              key={index}
              onClick={() => onItemSelected(index)}
              item={item}
            />
          );
        })}
      </ListContainer>
    </TitledPage>
  );
};

export default ListCategoriesPage;
