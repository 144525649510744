import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { TextInput } from "../../../../UIKit/TextInput/TextInput";
import { CirclePicker } from "react-color";
import { Project } from "../../../../Api/Resources/Projects/ProjectsApiTypes";
import { TrashSimple } from "phosphor-react";
import useProjectMutation from "../../../../Api/Resources/Projects/ProjectsMutation";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import useOrganisationsApi from "../../../../Api/Resources/Organisations/OrganisationsApi";
import shallow from "zustand/shallow";
import { useAuthStore } from "../../../../Store/AuthStore";
import useTeamsApi from "../../../../Api/Resources/Teams/TeamsApi";
import { Organisation } from "../../../../Api/Resources/Organisations/OrganisationsApiTypes";
import { Team } from "../../../../Api/Resources/Teams/TeamsApiTypes";
import { useQuery } from "react-query";

export const ModifyProjectModal = ({
  isOpen,
  onClose,
  project,
  allowTeamSelection = true,
  organisation,
  team,
}: {
  isOpen: boolean;
  onClose: () => void;
  project?: Project;
  allowTeamSelection?: boolean;
  organisation?: Organisation;
  team?: Team;
}) => {
  const { authTokens } = useAuthStore(
    (state) => ({ authTokens: state.authTokens }),
    shallow
  );

  const navigate = useNavigate();
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string | undefined>();
  const [color, setColor] = useState<string | undefined>("#4d4d4d");
  const organisationsApi = useOrganisationsApi();
  const teamsApi = useTeamsApi();

  const [requireApproval, setRequiresApproval] = useState<boolean>();

  const [selectedOrganisation, setSelectedOrganisation] = useState<{
    value: string;
    label: string;
  } | null>();

  const [selectedTeam, setSelectedTeam] = useState<{
    value: string;
    label: string;
  } | null>();

  const { data: _organisations, isFetching: isFetchingOrganisations } =
    useQuery(["organisations"], () => {
      return organisationsApi.list(authTokens?.userId!);
    });

  useEffect(() => {
    if (organisation && !selectedOrganisation) {
      setSelectedOrganisation({
        label: organisation.name!,
        value: organisation.id!,
      });
    }

    if (team && !selectedTeam) {
      setSelectedTeam({ label: team.name!, value: team.id! });
    }
  }, [organisation, team]);

  const { createProject, updateProject, deleteProject } = useProjectMutation();

  useEffect(() => {
    if (project) {
      setName(project.name);
      setColor(project.color);
      setDescription(project.description);
      setRequiresApproval(project.requireApproval);
    }
  }, [project, isOpen]);

  const isUpdating = useMemo(() => {
    return project !== undefined;
  }, [project]);

  const onModifyProject = () => {
    if (name !== undefined && name !== "") {
      if (isUpdating) {
        updateProject.mutate({
          ...project,
          organisationId: selectedOrganisation?.value,
          teamId: selectedTeam?.value,
          name,
          description,
          color,
          requireApproval,
        });
      } else {
        createProject.mutate({
          organisationId: selectedOrganisation?.value,
          teamId: selectedTeam?.value,
          name,
          description,
          color,
          requireApproval,
        });
      }
    }

    onPrepareModalClose();
  };

  const onDeleteProject = () => {
    if (project) {
      deleteProject.mutate({ projectId: project.id! });
      navigate("/projects");
    }
  };

  const onPrepareModalClose = () => {
    setName(undefined);
    setDescription(undefined);
    setColor("#4d4d4d");
    onClose();
  };

  const loadOrganisations = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    organisationsApi
      .list(authTokens?.userId!, inputValue)
      .then((organisations) => {
        const result = organisations.map((organisation) => {
          return {
            label: organisation.name ?? "Untitled Organisation",
            value: organisation.id!,
          };
        });

        callback(result);
      });
  };

  const loadTeams = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    teamsApi
      .listForOrganisation(selectedOrganisation?.value!, inputValue)
      .then((organisations) => {
        const result = organisations.map((organisation) => {
          return {
            label: organisation.name ?? "Untitled Organisation",
            value: organisation.id!,
          };
        });

        callback(result);
      });
  };

  const hasOrganisations = useMemo(() => {
    return (_organisations?.length ?? 0) > 0;
  }, [_organisations]);

  return (
    <Modal size={"lg"} isCentered isOpen={isOpen} onClose={onPrepareModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {`${isUpdating ? "Update" : "Create"}`} Project
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack width={"100%"} spacing={4}>
            <TextInput
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              placeholder="Name"
              label="Name"
              id="name"
            />
            <TextInput
              type="textarea"
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              placeholder="Description"
              label="Description"
              id="description"
            />
            {hasOrganisations && allowTeamSelection && (
              <AsyncSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "var(--chakra-colors-chakra-border-color)",
                    backgroundColor: "var(--chakra-colors-background100)",
                  }),
                }}
                isClearable
                placeholder="Select Organisation"
                value={selectedOrganisation}
                onChange={(value) => {
                  if (value !== selectedOrganisation) {
                    setSelectedOrganisation(null);
                  }

                  setSelectedOrganisation(value);
                }}
                cacheOptions
                defaultOptions={true}
                loadOptions={loadOrganisations}
              />
            )}
            {selectedOrganisation && allowTeamSelection && (
              <AsyncSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "var(--chakra-colors-chakra-border-color)",
                    backgroundColor: "var(--chakra-colors-background100)",
                  }),
                }}
                isClearable
                placeholder="Select Team"
                value={selectedTeam}
                onChange={(value) => {
                  if (value !== selectedTeam) {
                    setSelectedTeam(null);
                  }

                  setSelectedTeam(value);
                }}
                cacheOptions
                defaultOptions={true}
                loadOptions={loadTeams}
              />
            )}
            <FormControl>
              <FormLabel sx={{ opacity: 0.8 }}>Color</FormLabel>
              <CirclePicker
                colors={[
                  "#B3B3B3",
                  "#9F0500",
                  "#C45100",
                  "#FB9E00",
                  "#808900",
                  "#194D33",
                  "#0C797D",
                  "#0062B1",
                  "#653294",
                  "#AB149E",
                ]}
                color={color}
                onChangeComplete={(color) => setColor(color.hex)}
                width={"100%"}
              />
            </FormControl>

            {project?.team && (
              <FormControl>
                Enable approval for entries within this project.{" "}
                <Switch
                  isChecked={requireApproval ?? false}
                  onChange={(e) => setRequiresApproval(e.target.checked)}
                  size="lg"
                />
              </FormControl>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Flex width={"100%"} justifyContent={"space-between"}>
            {isUpdating ? (
              <Button
                rightIcon={<TrashSimple />}
                colorScheme="red"
                variant="outline"
                mr={3}
                onClick={onDeleteProject}
              >
                Delete
              </Button>
            ) : (
              <Box />
            )}
            <Flex>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button onClick={onModifyProject} colorScheme="blue">
                {`${isUpdating ? "Update" : "Create"}`} Project
              </Button>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
