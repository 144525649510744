import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { Coin, FileSearch, Graph } from "phosphor-react";
import { ReactElement } from "react";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

const ImageContainer = ({ image }: { image: string }) => {
  return (
    <Flex>
      <Image
        rounded={"md"}
        alt={"feature image"}
        src={image}
        objectFit={"cover"}
      />
    </Flex>
  );
};

export type FeatureItem = {
  text: string;
  backgroundColor: string;
  icon: ReactElement;
};

const SplitWithImage = ({
  align = "left",
  title,
  tagline,
  caption,
  features = [],
  image,
}: {
  title: string;
  align?: "left" | "right";
  tagline: string;
  caption: string;
  features?: FeatureItem[];
  image: string;
}) => {
  return (
    <Container maxW={"full"} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        {align === "left" && <ImageContainer image={image} />}
        <Stack spacing={4}>
          <Text
            textTransform={"uppercase"}
            color={"blue.400"}
            fontWeight={600}
            fontSize={"sm"}
            bg={useColorModeValue("blue.50", "blue.900")}
            p={2}
            alignSelf={"flex-start"}
            rounded={"md"}
          >
            {tagline}
          </Text>
          <Heading>{title}</Heading>
          <Text color={"gray.500"} fontSize={"lg"}>
            {caption}
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            {features.map((feature) => {
              return (
                <Feature
                  icon={feature.icon}
                  iconBg={feature.backgroundColor}
                  text={feature.text}
                />
              );
            })}
          </Stack>
        </Stack>
        {align === "right" && <ImageContainer image={image} />}
      </SimpleGrid>
    </Container>
  );
};

export default SplitWithImage;
