import { Category } from "./CategoriesApiTypes";
import CategoriesApi from "./CategoriesApi";
import { useMutation, useQueryClient } from "react-query";
import useCategoriesApi from "./CategoriesApi";

const useCategoryMutation = () => {
  const categoriesApi = useCategoriesApi();
  const queryClient = useQueryClient();

  const updateCategory = useMutation({
    mutationFn: (category: Category) => {
      return categoriesApi.update(category);
    },
    onSuccess: (data, variables) => {
      const { id } = variables;
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      queryClient.invalidateQueries({ queryKey: ["category", id] });
    },
  });

  const createCategory = useMutation({
    mutationFn: (category: Category) => {
      return categoriesApi.create(category);
    },
    onSuccess: (data, variables) => {
      const { id } = variables;
      queryClient.invalidateQueries({ queryKey: ["categories"] });
    },
  });

  const deleteCategory = useMutation({
    mutationFn: (options: { projectId: string; categoryId: string }) => {
      const { categoryId, projectId } = options;
      return categoriesApi.deleteOne(projectId, categoryId);
    },
    onSuccess: (data, variables) => {
      const { categoryId } = variables;
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      queryClient.invalidateQueries({ queryKey: ["category", categoryId] });
    },
  });

  return {
    createCategory,
    updateCategory,
    deleteCategory,
  };
};

export default useCategoryMutation;
