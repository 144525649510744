import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { TrashSimple } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Organisation } from "../../../../../Api/Resources/Organisations/OrganisationsApiTypes";
import useOrganisationMutation from "../../../../../Api/Resources/Organisations/OrganisationsMutation";
import { TextInput } from "../../../../../UIKit/TextInput/TextInput";

export const ModifyOrganisationModal = ({
  isOpen,
  onClose,
  organisation,
}: {
  isOpen: boolean;
  onClose: () => void;
  organisation?: Organisation;
}) => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string | undefined>();

  const { updateOrganisation, createOrganisation, deleteOrganisation } =
    useOrganisationMutation();

  useEffect(() => {
    if (organisation) {
      setName(organisation.name);
      setDescription(organisation.description);
    }
  }, [organisation, isOpen]);

  const isUpdating = useMemo(() => {
    return organisation !== undefined;
  }, [organisation]);

  const onModifyOrganisation = () => {
    if (name !== undefined && name !== "") {
      if (isUpdating) {
        updateOrganisation.mutate({
          ...organisation,
          name,
          description,
        });
      } else {
        createOrganisation.mutate({
          name,
          description,
        });
      }
    } else {
      alert("No name provided!");
    }

    onPrepareModalClose();
  };

  const onPrepareModalClose = () => {
    setName(undefined);
    setDescription(undefined);
    onClose();
  };

  const onDeleteOrganisation = async () => {
    if (organisation) {
      deleteOrganisation.mutate({ organisationId: organisation.id! });
      navigate("/account/organisations");
    }

    throw new Error("No entry to delete.");
  };

  return (
    <>
      <Modal
        size={"lg"}
        isCentered
        isOpen={isOpen}
        onClose={onPrepareModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {`${isUpdating ? "Update" : "Create"}`} Organisation
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack width={"100%"} spacing={4}>
              <TextInput
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                placeholder="Name"
                label="Name"
                id="name"
              />
              <TextInput
                type="textarea"
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
                placeholder="Description"
                label="Description"
                id="description"
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"space-between"}>
              {isUpdating ? (
                <Button
                  rightIcon={<TrashSimple />}
                  colorScheme="red"
                  variant="outline"
                  mr={3}
                  onClick={onDeleteOrganisation}
                >
                  Delete
                </Button>
              ) : (
                <Box />
              )}
              <Flex>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button onClick={onModifyOrganisation} colorScheme="blue">
                  {`${isUpdating ? "Update" : "Create"}`} Organisation
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
