import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import ListProjectsPage from "./Pages/Projects/List/ListProjectsPage";
import EntriesPage from "./Pages/Entries/List/ListEntriesPage";
import { QueryClient, QueryClientProvider } from "react-query";
import ViewProjectPage from "./Pages/Projects/View/ViewProjectPage";
import LoginPage from "./Pages/Login/LoginPage";
import shallow from "zustand/shallow";
import { useAuthStore } from "./Store/AuthStore";
import theme from "./Theme";
import { AccountPage } from "./Pages/Account/AccountPage";
import ListCategoriesPage from "./Pages/Categories/ListCategoriesPage";
import { BillingPage } from "./Pages/Account/Pages/Billing/BillingPage";
import CheckoutPage from "./Pages/Account/Pages/Checkout/CheckoutPage";
import CancelSubscription from "./Pages/Account/Pages/CancelSubscription/CancelSubscription";
import SettingsPage from "./Pages/Account/Pages/Settings/SettingsPage";
import ListOrganisationsPage from "./Pages/Account/Pages/Organisations/ListOrganisationsPage";
import ViewOrganisationPage from "./Pages/Account/Pages/Organisations/View/ViewOrganisationPage";
import ListTeamsPage from "./Pages/Teams/List/ListTeamsPage";
import HomeLanding from "./Pages/HomeLanding/HomeLanding";
import ViewTeamPage from "./Pages/Teams/View/ViewTeamPage";
import ViewPersonPage from "./Pages/Users/View/ViewUserPage";
import UnauthorisedPage from "./Pages/Unauthorised/UnauthorisedPage";
import ViewPersonalDetails from "./Pages/Account/Pages/PersonalDetails/ViewPersonalDetails";

const Routing = () => {
  const { authTokens } = useAuthStore(
    (state) => ({ authTokens: state.authTokens }),
    shallow
  );

  const router = (isLoggedIn: boolean) =>
    createBrowserRouter([
      {
        path: "/",
        element: isLoggedIn ? (
          <App showFooter={false} isLoggedIn={true} />
        ) : (
          <Navigate to="/landing" />
        ),
        children: [
          {
            path: "",
            element: <EntriesPage />,
          },
          {
            path: "projects",
            element: <ListProjectsPage />,
          },
          {
            path: "projects/:projectId",
            element: <ViewProjectPage />,
          },
          {
            path: "teams",
            element: <ListTeamsPage />,
          },
          {
            path: "people/:personId",
            element: <ViewPersonPage />,
          },
          {
            path: "teams/:teamId",
            element: <ViewTeamPage />,
          },
          {
            path: "account",
            element: <AccountPage />,
          },
          {
            path: "/projects/:projectId/categories",
            element: <ListCategoriesPage />,
          },
          {
            path: "account/personal-details",
            element: <ViewPersonalDetails />,
          },
          {
            path: "account/billing",
            element: <BillingPage />,
          },
          {
            path: "account/billing/checkout",
            element: <CheckoutPage />,
          },
          {
            path: "account/billing/cancel",
            element: <CancelSubscription />,
          },
          {
            path: "account/settings",
            element: <SettingsPage />,
          },
          {
            path: "account/organisations",
            element: <ListOrganisationsPage />,
          },
          {
            path: "organisations/:organisationId",
            element: <ViewOrganisationPage />,
          },
          {
            path: "unauthorised",
            element: <UnauthorisedPage />,
          },
        ],
      },
      {
        path: "/login",
        element: isLoggedIn ? (
          <Navigate to="/" />
        ) : (
          <App
            showPageHeader={false}
            showFooter={false}
            showNav={false}
            isLoggedIn={false}
          />
        ),
        children: [{ path: "", element: <LoginPage /> }],
      },
      {
        path: "/sign-up",
        element: isLoggedIn ? (
          <Navigate to="/" />
        ) : (
          <App
            showPageHeader={false}
            showFooter={false}
            showNav={false}
            isLoggedIn={false}
          />
        ),
        children: [{ path: "", element: <LoginPage isSignUp={true} /> }],
      },
      {
        path: "/",
        element: isLoggedIn ? (
          <Navigate to="/" />
        ) : (
          <App showPageHeader={false} showFooter={false} isLoggedIn={false} />
        ),
        children: [{ path: "landing", element: <HomeLanding /> }],
      },
    ]);

  return (
    <RouterProvider router={router(authTokens?.accessToken !== undefined)} />
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />

    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Routing />
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
