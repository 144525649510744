import React, { useState } from "react";
import { Props as DayzedHookProps } from "dayzed";
import { Month_Names_Short, Weekday_Names_Short } from "./Utils/CalendarUtils";
import {
  Box,
  Flex,
  IconButton,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { CalendarPanel } from "./Components/CalendarPanel";
import {
  CalendarConfigs,
  DatepickerConfigs,
  DatepickerProps,
  OnDateSelected,
  PropsConfigs,
} from "./Utils/CommonTypes";
import { format } from "date-fns";
import { Calendar } from "phosphor-react";

interface RangeCalendarPanelProps {
  dayzedHookProps: DayzedHookProps;
  configs: CalendarConfigs;
  propsConfigs?: PropsConfigs;
  selected?: Date | Date[];
}

const RangeCalendarPanel: React.FC<RangeCalendarPanelProps> = ({
  dayzedHookProps,
  configs,
  propsConfigs,
  selected,
}) => {
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

  // Calendar level
  const onMouseLeave = () => {
    setHoveredDate(null);
  };

  // Date level
  const onMouseEnterHighlight = (date: Date) => {
    if (!Array.isArray(selected) || !selected?.length) {
      return;
    }
    setHoveredDate(date);
  };

  const isInRange = (date: Date) => {
    if (!Array.isArray(selected) || !selected?.length) {
      return false;
    }
    let firstSelected = selected[0];
    if (selected.length === 2) {
      let secondSelected = selected[1];
      return firstSelected < date && secondSelected > date;
    } else {
      return (
        hoveredDate &&
        ((firstSelected < date && hoveredDate >= date) ||
          (date < firstSelected && date >= hoveredDate))
      );
    }
  };

  return (
    <Flex onMouseLeave={onMouseLeave}>
      <CalendarPanel
        dayzedHookProps={dayzedHookProps}
        configs={configs}
        propsConfigs={propsConfigs}
        isInRange={isInRange}
        onMouseEnterHighlight={onMouseEnterHighlight}
      />
    </Flex>
  );
};

export interface RangeDatePickerProps extends DatepickerProps {
  selectedDates?: Date[];
  configs?: DatepickerConfigs;
  disabled?: boolean;
  defaultIsOpen?: boolean;
  closeOnSelect?: boolean;
  onDateChange: (date: Date[]) => void;
  id?: string;
  name?: string;
  usePortal?: boolean;
}

const DefaultConfigs: CalendarConfigs = {
  dateFormat: "yyyy-MM-dd",
  monthNames: Month_Names_Short,
  dayNames: Weekday_Names_Short,
  firstDayOfWeek: 0,
};

export const RangeDatePicker: React.FC<RangeDatePickerProps> = ({
  configs,
  propsConfigs = {},
  id,
  name,
  usePortal,
  defaultIsOpen = false,
  closeOnSelect = true,
  ...props
}) => {
  const {
    selectedDates = [],
    minDate,
    maxDate,
    onDateChange,
    disabled,
  } = props;

  // chakra popover utils
  const [dateInView, setDateInView] = useState<Date | undefined>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const [offset, setOffset] = useState(0);
  const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen });

  const calendarConfigs: CalendarConfigs = {
    ...DefaultConfigs,
    ...configs,
  };

  const onPopoverClose = () => {
    onClose();
    setDateInView(undefined);
    setOffset(0);
  };

  const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
    if (!selectable) {
      return;
    }

    let newDates = [...selectedDates];

    if (selectedDates.length) {
      // If there is already one selected date...
      if (selectedDates.length === 1) {
        let first = selectedDates[0];

        // Place the dates in the correct order...
        if (first < date) {
          newDates.push(date);
        } else {
          newDates.unshift(date);
        }

        // Update the input fields...
        onDateChange(newDates);

        // Set the start and end date fields...
        setStartDate(format(newDates[0], calendarConfigs.dateFormat));
        setEndDate(format(newDates[1], calendarConfigs.dateFormat));

        if (closeOnSelect) onClose();
        return;
      }

      // If there's already two dates selected...
      if (newDates.length === 2) {
        setStartDate(format(newDates[0], calendarConfigs.dateFormat));
        setEndDate(format(newDates[1], calendarConfigs.dateFormat));

        onDateChange([date]);
        return;
      }
    } else {
      newDates.push(date);
      onDateChange(newDates);
    }
  };

  const PopoverContentWrapper = usePortal ? Portal : React.Fragment;

  return (
    <Popover
      placement="bottom-start"
      variant="responsive"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onPopoverClose}
      isLazy
    >
      <Flex position={"relative"}>
        <Input
          marginRight={"-1px"}
          borderRadius={"4px 0 0 4px"}
          onKeyPress={(e) => {
            if (e.key === " " && !isOpen) {
              onOpen();
            }
          }}
          id={id}
          autoComplete="off"
          isDisabled={disabled}
          name={name}
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
          {...propsConfigs.inputProps}
        />
        <Input
          borderRadius={0}
          onKeyPress={(e) => {
            if (e.key === " " && !isOpen) {
              onOpen();
            }
          }}
          id={id}
          autoComplete="off"
          isDisabled={disabled}
          name={name}
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
          {...propsConfigs.inputProps}
        />
        <PopoverTrigger>
          <IconButton
            borderRadius={"0 4px 4px 0"}
            aria-label="Open Calendar"
            icon={<Calendar />}
          />
        </PopoverTrigger>
      </Flex>
      <PopoverContentWrapper>
        <PopoverContent
          width="100%"
          {...propsConfigs?.popoverCompProps?.popoverContentProps}
        >
          <PopoverBody {...propsConfigs.popoverCompProps?.popoverBodyProps}>
            <RangeCalendarPanel
              dayzedHookProps={{
                onDateSelected: handleOnDateSelected,
                selected: selectedDates,
                monthsToDisplay: 2,
                date: dateInView,
                minDate: minDate,
                maxDate: maxDate,
                offset: offset,
                onOffsetChanged: setOffset,
                firstDayOfWeek: calendarConfigs.firstDayOfWeek,
              }}
              configs={calendarConfigs}
              propsConfigs={propsConfigs}
              selected={selectedDates}
            />
          </PopoverBody>
        </PopoverContent>
      </PopoverContentWrapper>
    </Popover>
  );
};
