import {
  Box,
  Text,
  Stack,
  List,
  ListItem,
  ListIcon,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { Check, CheckCircle } from "phosphor-react";
import { Plan } from "../../Pages/Account/Pages/Billing/BillingPage";

const DAYBREAK_PLUS_STRIPE_URL = `https://buy.stripe.com/test_bIYeXM9uU4IffkYcMM`;

const PricingCard = ({
  isSelected = false,
  onSelect,
  plan,
}: {
  plan: Plan;
  isSelected?: boolean;
  onSelect: (plan: Plan) => void;
}) => {
  const { planName, price, points } = plan;

  return (
    <Box
      sx={{ position: "relative", transition: "all 0.3s" }}
      width={"300px"}
      outline={
        isSelected
          ? "3px solid var(--chakra-colors-blue500)"
          : "1px solid var(--chakra-colors-border200)"
      }
      bg={useColorModeValue("white", "gray.800")}
      rounded={"md"}
      overflow={"hidden"}
    >
      {isSelected && (
        <CheckCircle
          color="var(--chakra-colors-blue500)"
          style={{ position: "absolute", right: 4, top: 4 }}
          size="36"
          weight="fill"
        />
      )}
      <Stack
        textAlign={"center"}
        p={6}
        color={useColorModeValue("gray.800", "white")}
        align={"center"}
      >
        <Text
          fontSize={"sm"}
          fontWeight={500}
          bg={useColorModeValue("blue.50", "blue.900")}
          p={2}
          px={3}
          color={"blue.500"}
          rounded={"full"}
        >
          {planName}
        </Text>
        <Stack direction={"row"} align={"center"} justify={"center"}>
          <Text fontSize={"3xl"}>$</Text>
          <Text fontSize={"6xl"} fontWeight={800}>
            {price}
          </Text>
          <Text color={"gray.500"}>/month</Text>
        </Stack>
      </Stack>

      <Box bg={useColorModeValue("gray.50", "gray.900")} px={6} py={10}>
        <List spacing={3}>
          {points.map((point, index) => {
            return (
              <ListItem key={index}>
                <ListIcon as={Check} color="green.400" />
                {point}
              </ListItem>
            );
          })}
        </List>

        <Button
          onClick={() => {
            onSelect(plan);
          }}
          colorScheme={isSelected ? undefined : "orange"}
          variant={isSelected ? "outline" : "solid"}
          disabled={isSelected}
          mt={10}
          w={"full"}
        >
          {`${isSelected ? "Your Current Plan" : "Choose Plan"}`}
        </Button>
      </Box>
    </Box>
  );
};

export default PricingCard;
