import { Flex } from "@chakra-ui/react";
import styles from "./PageHeader.module.scss";

const PageHeader = ({
  title,
}: {
  title: string;
  subtitle?: string;
  isLoading?: boolean;
}) => {
  return (
    <Flex flexDirection={"row"}>
      <Flex className={`${styles.title} `}>{title}</Flex>
    </Flex>
  );
};

export default PageHeader;
