import TitledPage from "../../../Components/TitledPage";
import { Info, ListDashes, Plus, Sun } from "phosphor-react";
import ListContainer from "../../../UIKit/List/List";
import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./ListEntriesPage.module.scss";
import EntriesApi from "../../../Api/Resources/Entries/EntriesApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Placeholder from "../../../UIKit/Placeholder/Placeholder";
import DateBar from "../../../UIKit/DateBar.tsx/DateBar";
import { format, isEqual, formatISO, endOfDay } from "date-fns";
import { endOfWeek, startOfDay, startOfWeek } from "date-fns/esm";
import { EntriesListItem } from "./Components/EntriesListItem/EntriesListItem";
import {
  Entry,
  EntryMetaData,
} from "../../../Api/Resources/Entries/EntriesApiTypes";
import { ModifyEntriesModal } from "./Components/ModifyEntriesModal";
import { EntryStatsModal } from "./Components/EntryStatsModal/EntryStatsModal";
import { Platform, usePlatformStore } from "../../../Store/PlatformStore";
import shallow from "zustand/shallow";
import useWindowSize from "../../../Hooks/useWindowSize";
import { useEntryStore } from "../../../Store/EntryStore";
import useInterval from "../../../Hooks/useInterval";
import { ItemType, ListItemHeader } from "../../../UIKit/ListItem/ListItem";
import produce from "immer";
import { parseISO } from "date-fns";
import { useUserId } from "../../../Store/AuthStore";

const EntriesPage = () => {
  const queryClient = useQueryClient();
  const [timedEntry, setTimedEntry] = useState<Entry>();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEntry, setSelectedEntry] = useState<Entry>();
  const userId = useUserId();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEntryStatsOpen,
    onOpen: onEntryStatsOpen,
    onClose: onEntryStatsClose,
  } = useDisclosure();

  const entriesApi = EntriesApi();

  const { data: _entries, isFetching: isFetchingEntries } = useQuery(
    ["entries", selectedDate, userId],
    () => {
      return entriesApi.listForUser({
        startDate: format(startOfDay(selectedDate), "yyyy-MM-dd"),
        endDate: format(endOfDay(selectedDate), "yyyy-MM-dd"),
        userId: userId!,
      });
    }
  );

  const { data: _metadata, isFetching: isFetchingMetaData } = useQuery(
    ["metadata"],
    () => {
      return entriesApi.getMetaData(
        format(startOfWeek(selectedDate), "yyyy-MM-dd"),
        format(endOfWeek(selectedDate), "yyyy-MM-dd")
      );
    }
  );

  useEffect(() => {
    if (_metadata?.activeEntry) {
      setTimedEntry(_metadata?.activeEntry);
    }
  }, [_metadata]);

  const startTimer = useMutation(
    (options: { startedAt: Date; id: string; index: number }) => {
      const { startedAt, id } = options;
      return entriesApi.startTimer({ id, startedAt: formatISO(startedAt) });
    },
    {
      onSuccess: (data, options) => {
        // queryClient.invalidateQueries({ queryKey: ["entries", selectedDate] });
        // queryClient.invalidateQueries({ queryKey: ["totals"] });
      },
    }
  );

  const stopTimer = useMutation({
    mutationFn: (options: { id: string; index: number; addedTime: number }) => {
      const { id, addedTime } = options;
      return entriesApi.stopTimer({ id, addedTime });
    },
    // When mutate is called...
    onMutate: async (options: {
      id: string;
      index: number;
      addedTime: number;
    }) => {
      const { index, addedTime } = options;

      const previousEntries = queryClient.getQueryData([
        "entries",
        selectedDate,
        userId,
      ]) as Entry[];

      const updatedEntries = produce(previousEntries, (draft) => {
        console.log("Draft is", draft);
        draft[index].time = draft[index].time + addedTime;
      });

      const previousTotals = queryClient.getQueryData([
        "metadata",
      ]) as EntryMetaData;

      const changedDate = format(
        parseISO(previousEntries[index].date),
        "yyyy-MM-dd"
      );

      const updatedTotals = produce(previousTotals, (draft) => {
        draft.entryTotals[changedDate] =
          draft.entryTotals[changedDate] + addedTime;
        draft.totalTime = draft.totalTime + addedTime;
      });

      console.log(addedTime, previousEntries, updatedEntries);
      console.log(addedTime, previousTotals, updatedTotals);

      queryClient.setQueryData(
        ["entries", selectedDate, userId],
        updatedEntries
      );
      queryClient.setQueryData(["metadata"], updatedTotals);

      // Return a context object with the snapshotted value
      return { previousEntries, previousTotals };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        ["entries", selectedDate],
        context?.previousEntries
      );
      queryClient.setQueryData(["entryTotals"], context?.previousTotals);
    },
    // Always refetch after error or success:
    onSettled: () => {
      // queryClient.invalidateQueries({ queryKey: ["entries"] });
      // queryClient.invalidateQueries({ queryKey: ["entryTotals"] });
    },
  });

  const isThisWeek = useMemo(() => {
    return isEqual(startOfWeek(selectedDate), startOfWeek(new Date()));
  }, [selectedDate]);

  const onItemSelected = (index: number) => {
    if (_entries) {
      const entry = _entries[index];
      setSelectedEntry(entry);
      onOpen();
    }
  };

  const onPrepareModalClose = () => {
    setSelectedEntry(undefined);
    onClose();
  };

  const size = useWindowSize();

  const isMinified = useMemo(() => {
    return (size?.width ?? 0) < 600;
  }, [size]);

  const { setAdditionalTime, additionalTime } = useEntryStore(
    (state) => ({
      additionalTime: state.additionalTime,
      setAdditionalTime: state.setAdditionalTime,
    }),
    shallow
  );

  useInterval(
    () => {
      setAdditionalTime(additionalTime + 1);
    },
    timedEntry ? 1000 : null
  );

  return (
    <TitledPage
      isResponsive={false}
      title={"Timesheet"}
      action={[
        {
          label: "New Entry",
          icon: Plus,
          trigger: onOpen,
        },
        {
          icon: Info,
          helpText: "View Entry Stats",
          trigger: onEntryStatsOpen,
        },
      ]}
    >
      <DateBar
        isMinified={isMinified}
        totals={_metadata?.entryTotals}
        onDateChange={(date) => setSelectedDate(date)}
        selectedDate={selectedDate}
        timedEntry={timedEntry}
      />
      <EntryStatsModal
        totalTime={_metadata?.totalTime}
        totalEntries={_metadata?.totalEntries}
        onClose={onEntryStatsClose}
        isOpen={isEntryStatsOpen}
      />
      <ModifyEntriesModal
        selectedDate={selectedDate}
        isOpen={isOpen}
        onClose={onPrepareModalClose}
        entry={selectedEntry}
        order={_entries?.length ?? 0}
      />
      {(_entries ?? []).length === 0 ? (
        <Placeholder
          icon={ListDashes}
          isLoading={isFetchingEntries}
          message={"No entries for day"}
        />
      ) : (
        <ListContainer>
          <>
            {(_entries?.length ?? 0) > 0 && (
              <ListItemHeader
                item={{
                  type: ItemType.Header,
                  label: `${format(selectedDate, "EEEE d MMMM, yyyy")}`,
                  id: "my_entries",
                }}
              />
            )}
            {(_entries ?? []).map((item, index) => {
              const color = item.project?.color;
              return (
                <EntriesListItem
                  key={index}
                  color={color}
                  isTiming={item.id === timedEntry?.id}
                  onClick={() => onItemSelected(index)}
                  item={item}
                  onPlay={() => {
                    setTimedEntry(item);
                    startTimer.mutate({
                      id: item.id!,
                      startedAt: new Date(),
                      index,
                    });
                  }}
                  onPause={() => {
                    setTimedEntry(undefined);
                    stopTimer.mutate({
                      id: item.id!,
                      index,
                      addedTime: additionalTime,
                    });
                  }}
                />
              );
            })}
          </>
        </ListContainer>
      )}
      <Flex
        className={`${styles.backToTodayButtonContainer} ${
          !isThisWeek ? styles.active : ""
        }`}
      >
        <Button
          leftIcon={<Sun weight="bold" />}
          onClick={() => setSelectedDate(new Date())}
          className={styles.backToTodayButton}
        >
          Back to Today
        </Button>
      </Flex>
    </TitledPage>
  );
};

export default EntriesPage;
