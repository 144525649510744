import {
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconProps,
  useColorModeValue,
} from "@chakra-ui/react";
import ResponsiveContainer from "../../UIKit/ResponsiveContainer/ResponsiveContainer";
import HighlightsSection from "./Components/HighlightsSection";
import Hero from "../../Assets/hero-1.png";
import {
  AppleLogo,
  ArrowClockwise,
  ChartBar,
  ChartPieSlice,
  Coin,
  Coins,
  CurrencyDollar,
  UsersThree,
} from "phosphor-react";
import SplitWithImage from "../../UIKit/SplitWithImage/SplitWithImage";
import Footer from "../../UIKit/Footer/Footer";
import GroupImage from "../../Assets/group.jpg";
import IndividualImage from "../../Assets/individual.jpg";
import { NavLink } from "react-router-dom";

const HomeLanding = () => {
  return <CallToActionWithVideo />;
};

const CallToActionWithVideo = () => {
  return (
    <>
      <Box
        bg={"var(--chakra-colors-background300)"}
        paddingBottom={20}
        paddingTop={8}
        borderBottom={"1px solid var(--chakra-colors-border100)"}
      >
        <ResponsiveContainer>
          <Stack
            align={"center"}
            spacing={{ base: 8, md: 10 }}
            direction={{ base: "column", md: "row" }}
          >
            <Stack flex={1} spacing={{ base: 5, md: 10 }}>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
              >
                <Text
                  as={"span"}
                  position={"relative"}
                  _after={{
                    content: "''",
                    width: "full",
                    height: "30%",
                    position: "absolute",
                    bottom: 1,
                    left: 0,
                    bg: "red.400",
                    zIndex: -1,
                  }}
                >
                  Time tracking
                </Text>
                <br />
                <Text as={"span"} color={"yellow400"}>
                  made simple.
                </Text>
              </Heading>

              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={{ base: "column", sm: "row" }}
              >
                <NavLink to="/login">
                  <Button
                    rounded={"full"}
                    size={"lg"}
                    fontWeight={"normal"}
                    px={6}
                    colorScheme={"red"}
                    bg={"orange.400"}
                    _hover={{ bg: "orange.500" }}
                  >
                    Get started
                  </Button>
                </NavLink>
                <a href="https://apps.apple.com/us/app/daybreak-time-tracking/id1558197906">
                  <Button
                    rounded={"full"}
                    size={"lg"}
                    fontWeight={"normal"}
                    px={6}
                    leftIcon={
                      <AppleLogo
                        color="var(--chakra-colors-background500)"
                        size={18}
                        weight="fill"
                      />
                    }
                  >
                    Download for macOS
                  </Button>
                </a>
              </Stack>
            </Stack>
            <Flex
              flex={1}
              justify={"center"}
              align={"center"}
              position={"relative"}
              w={"full"}
            >
              <Blob
                w={"150%"}
                h={"150%"}
                position={"absolute"}
                top={"-20%"}
                left={0}
                zIndex={-1}
                color={useColorModeValue("red.50", "red.400")}
              />
              <Flex
                alignItems={"flex-end"}
                position={"relative"}
                width={"full"}
                justifyContent={"flex-end"}
              >
                {/* <IconButton
              aria-label={"Play Button"}
              variant={"ghost"}
              _hover={{ bg: "transparent" }}
              icon={<Play size={24} />}
              size={"lg"}
              color={"white"}
              position={"absolute"}
              left={"50%"}
              top={"50%"}
              transform={"translateX(-50%) translateY(-50%)"}
            /> */}
                <Image
                  alt={"Hero Image"}
                  fit={"contain"}
                  align={"center"}
                  w={"80%"}
                  src={Hero}
                />
              </Flex>
            </Flex>
          </Stack>
        </ResponsiveContainer>
      </Box>
      <ResponsiveContainer>
        <SplitWithImage
          image={IndividualImage}
          caption={
            "Daybreak simplifies time tracking for freelancers and individuals with its user-friendly interface. It enables effective time management, improved productivity, and streamlined workflows."
          }
          tagline={"For Individuals"}
          title={"Streamlined Time Management"}
          features={[
            {
              text: "Sync data across devices",
              icon: (
                <Icon as={ArrowClockwise} color={"yellow.500"} w={5} h={5} />
              ),
              backgroundColor: useColorModeValue("yellow.100", "yellow.900"),
            },
            {
              text: "Integrated Reporting",
              icon: (
                <Icon as={ChartPieSlice} color={"purple.500"} w={5} h={5} />
              ),
              backgroundColor: useColorModeValue("purple.100", "purple.900"),
            },
            {
              text: "Low Cost (starting at $0/month)",
              icon: <Icon as={Coin} color={"green.500"} w={5} h={5} />,
              backgroundColor: useColorModeValue("green.100", "green.900"),
            },
          ]}
        />
        <SplitWithImage
          image={GroupImage}
          caption={
            "Effortlessly manage a team, monitor your project performance, productivity, and automate client billing for seamless invoicing."
          }
          tagline={"For Business"}
          title={"Business Tracking Superpowers"}
          align="right"
          features={[
            {
              text: "Analyse Project Performance",
              icon: <Icon as={ChartBar} color={"yellow.500"} w={5} h={5} />,
              backgroundColor: useColorModeValue("yellow.100", "yellow.900"),
            },
            {
              text: "View Team Member Productivity",
              icon: <Icon as={UsersThree} color={"purple.500"} w={5} h={5} />,
              backgroundColor: useColorModeValue("purple.100", "purple.900"),
            },
            {
              text: "Track & Estimate Project Costs",
              icon: <Icon as={Coins} color={"green.500"} w={5} h={5} />,
              backgroundColor: useColorModeValue("green.100", "green.900"),
            },
          ]}
        />
        <Box height={20} />
        <HighlightsSection />
      </ResponsiveContainer>

      <Box height={"140px"} />
      <Footer />
    </>
  );
};

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default HomeLanding;
