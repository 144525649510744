import {
  FormControl,
  Input, Textarea
} from "@chakra-ui/react";
import React from "react";

export const TextInput = React.forwardRef((props: any, ref: any) => {
  const CustomInput = props.type === "textarea" ? Textarea : Input;
  return (
    <FormControl>
      <CustomInput ref={ref} id={props.id} {...props} />
    </FormControl>
  );
});
