import { Avatar, Flex, HStack, Td } from "@chakra-ui/react";
import styles from "./Table.module.scss";
import { RenderRowOptions } from "./Table";
import { User } from "../../Api/Resources/Users/UsersApiTypes";
import { UserHighlight } from "../../Components/UserHighlight";

export const UserTableRow = ({
  column,
  columnIndex,
  row,
  rowIndex,
  totalRows,
}: RenderRowOptions) => {
  const user = row[column.accessor] as User;

  return (
    <Td
      key={`${rowIndex}-${columnIndex}`}
      borderBottom={rowIndex === totalRows - 1 ? 0 : undefined}
      isNumeric={column.isNumeric}
      className={`${column.isLink ? styles.link : ""}`}
    >
      <UserHighlight user={user} />
    </Td>
  );
};
