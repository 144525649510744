import { Flex, Heading, Text, Button } from "@chakra-ui/react";

const UnauthorisedPage = () => {
  return (
    <Flex
      height="100vh"
      align="center"
      justify="center"
      direction="column"
      textAlign="center"
    >
      <Heading fontSize="6xl" mb={4}>
        Oops! You can't go here.
      </Heading>
      <Text fontSize="xl" mb={8}>
        It seems like you don't have permission to access this resource.
      </Text>
      <Button
        colorScheme="teal"
        size="lg"
        onClick={() => window.location.replace("/")}
      >
        Go Home
      </Button>
    </Flex>
  );
};

export default UnauthorisedPage;
