import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { TrashSimple } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { Category } from "../../../../Api/Resources/Categories/CategoriesApiTypes";
import useCategoryMutation from "../../../../Api/Resources/Categories/CategoriesMutation";
import { TextInput } from "../../../../UIKit/TextInput/TextInput";

export const ModifyCategoryModal = ({
  isOpen,
  onClose,
  category,
  projectId,
}: {
  isOpen: boolean;
  onClose: () => void;
  category?: Category;
  projectId: string;
}) => {
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string | undefined>();

  const { updateCategory, createCategory, deleteCategory } =
    useCategoryMutation();

  useEffect(() => {
    if (category) {
      setName(category.name);
      setDescription(category.description);
    }
  }, [category, isOpen]);

  const isUpdating = useMemo(() => {
    return category !== undefined;
  }, [category]);

  const onModifyCategory = () => {
    if (name !== undefined && name !== "") {
      if (isUpdating) {
        updateCategory.mutate({
          ...category,
          name,
          description,
          projectId,
        });
      } else {
        createCategory.mutate({
          name,
          description,
          projectId,
        });
      }
    } else {
      alert("No name provided!");
    }

    onPrepareModalClose();
  };

  const onPrepareModalClose = () => {
    setName(undefined);
    setDescription(undefined);
    onClose();
  };

  const onDeleteCategory = async () => {
    if (category) {
      deleteCategory.mutate({ projectId, categoryId: category.id! });
      onPrepareModalClose();
      return;
    }

    throw new Error("No entry to delete.");
  };

  return (
    <>
      <Modal
        size={"lg"}
        isCentered
        isOpen={isOpen}
        onClose={onPrepareModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`${isUpdating ? "Update" : "Create"}`} Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack width={"100%"} spacing={4}>
              <TextInput
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                placeholder="Name"
                label="Name"
                id="name"
              />
              <TextInput
                type="textarea"
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
                placeholder="Description"
                label="Description"
                id="description"
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"space-between"}>
              {isUpdating ? (
                <Button
                  rightIcon={<TrashSimple />}
                  colorScheme="red"
                  variant="outline"
                  mr={3}
                  onClick={onDeleteCategory}
                >
                  Delete
                </Button>
              ) : (
                <Box />
              )}
              <Flex>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button onClick={onModifyCategory} colorScheme="blue">
                  {`${isUpdating ? "Update" : "Create"}`} Category
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
