import { Flex, FlexProps, Text } from "@chakra-ui/react";
import styles from "./Separator.module.scss";

const Separator = (
  props: {
    text?: string;
  } & FlexProps
) => {
  const { text } = props;

  return (
    <Flex {...props} className={styles.container}>
      <Flex className={styles.line} />
      {text && <Text className={styles.text}>{text}</Text>}
      <Flex className={styles.line} />
    </Flex>
  );
};

export default Separator;
