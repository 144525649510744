import { Flex } from "@chakra-ui/react";
import { Category } from "../../../Api/Resources/Categories/CategoriesApiTypes";
import ResponsiveContainer from "../../../UIKit/ResponsiveContainer/ResponsiveContainer";
import styles from "./CategoriesListItem.module.scss";

export const CategoriesListItem = ({
  item,
  onClick,
}: {
  item: Category;
  onClick?: () => void;
}) => {
  return (
    <Flex onClick={onClick} className={styles.listItem}>
      <ResponsiveContainer>
        <Flex alignItems={"center"}>
          <Flex className={styles.container}>
            <Flex className={styles.content}>
              <Flex className={styles.name}>{item.name}</Flex>
              <Flex className={styles.description}>{item.description}</Flex>
            </Flex>
          </Flex>
        </Flex>
      </ResponsiveContainer>
    </Flex>
  );
};
