import { useMutation, useQueryClient } from "react-query";
import useTeamsApi from "./TeamsApi";
import {
  AddTeamMemberReqest,
  CreateTeamRequest,
  RemoveTeamMemberReqest,
  Team,
  UpdateTeamRequest,
} from "./TeamsApiTypes";

const useTeamsMutation = () => {
  const teamsApi = useTeamsApi();
  const queryClient = useQueryClient();

  const updateTeam = useMutation({
    mutationFn: (team: UpdateTeamRequest) => {
      return teamsApi.update(team);
    },
    onSuccess: (data, variables) => {
      const { id } = variables;
      queryClient.invalidateQueries({ queryKey: ["teams"] });
      queryClient.invalidateQueries({ queryKey: ["team", id] });
    },
  });

  const createTeam = useMutation({
    mutationFn: (team: CreateTeamRequest) => {
      return teamsApi.create(team);
    },
    onSuccess: (data, variables) => {
      const { id } = variables;
      queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
  });

  const addTeamMember = useMutation({
    mutationFn: (addTeamMemberRequest: AddTeamMemberReqest) => {
      return teamsApi.addTeamMember(addTeamMemberRequest);
    },
    onSuccess: (data, variables) => {
      const { teamId } = variables;
      queryClient.invalidateQueries({ queryKey: ["teams"] });
      queryClient.invalidateQueries({ queryKey: ["teams", teamId] });
    },
  });

  const removeTeamMember = useMutation({
    mutationFn: (removeTeamMemberRequest: RemoveTeamMemberReqest) => {
      return teamsApi.removeTeamMember(removeTeamMemberRequest);
    },
    onSuccess: (data, variables) => {
      const { teamId } = variables;
      queryClient.invalidateQueries({ queryKey: ["teams"] });
      queryClient.invalidateQueries({ queryKey: ["teams", teamId] });
    },
  });


  const deleteTeam = useMutation({
    mutationFn: (options: { teamId: string }) => {
      const { teamId } = options;
      return teamsApi.deleteOne(teamId);
    },
    onSuccess: (data, variables) => {
      const { teamId } = variables;
      queryClient.invalidateQueries({ queryKey: ["teams"] });
      queryClient.invalidateQueries({ queryKey: ["team", teamId] });
    },
  });

  return {
    createTeam,
    updateTeam,
    deleteTeam,
    addTeamMember,
    removeTeamMember
  };
};

export default useTeamsMutation;
