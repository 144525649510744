import { Td, Avatar } from "@chakra-ui/react";
import { RenderRowOptions } from "./Table";

export const AvatarTableRow = ({
  row,
  column,
  rowIndex,
  totalRows,
}: RenderRowOptions) => {
  return (
    <Td
      borderBottom={rowIndex === totalRows - 1 ? 0 : undefined}
      width={"42px"}
    >
      <Avatar name={(row[column.accessor] as string) ?? ""} />
    </Td>
  );
};
