import { useMutation, useQueryClient } from "react-query";
import useUsersApi from "./UsersApi";

const useUserMutation = () => {
  const usersApi = useUsersApi();
  const queryClient = useQueryClient();

  const acceptOrganisationInvite = useMutation({
    mutationFn: (options: { inviteId: string; userId: string }) => {
      return usersApi.acceptInvite(options);
    },
    onSuccess: (data, variables) => {
      const { userId } = variables;
      queryClient.invalidateQueries({ queryKey: ["userInvitations", userId] });
    },
  });

  const ignoreOrganisationInvite = useMutation({
    mutationFn: (options: { inviteId: string; userId: string }) => {
      return usersApi.ignoreInvite(options);
    },
    onSuccess: (data, variables) => {
      const { userId } = variables;
      queryClient.invalidateQueries({ queryKey: ["userInvitations", userId] });
    },
  });

  return {
    ignoreOrganisationInvite,
    acceptOrganisationInvite,
  };
};

export default useUserMutation;
