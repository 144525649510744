import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Box,
  Stack,
  Heading,
  Text,
  Switch,
  GridItem,
  Grid,
  useColorModeValue,
  Tr,
  Td,
  Tbody,
  Table,
  Thead,
  Th,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";

import { useState } from "react";

// Define the expected structure for the prop data
interface Plan {
  name: string;
  priceMonthly: string;
  priceYearly: string;
  features: { [key: string]: string };
}

interface PricingTableProps {
  plans: Plan[];
  highlightPlan: string; // name of the plan to highlight
}

const PricingTable = ({ plans, highlightPlan }: PricingTableProps) => {
  const [isYearly, setIsYearly] = useState(true);
  const toggleBilling = () => setIsYearly(!isYearly);

  const highlightBg = useColorModeValue("gray.100", "gray.700");

  return (
    <Box p={8}>
      <Heading textAlign="center" mb={8}>
        Choose a plan that's right for you
      </Heading>

      {/* Billing toggle */}
      <Stack direction="row" justify="center" align="center" mb={6}>
        <Text>Monthly billing</Text>
        <Switch onChange={toggleBilling} isChecked={isYearly} mx={2} />
        <Text>Yearly (Save up to 40%)</Text>
      </Stack>

      {/* Pricing Table */}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th></Th> {/* Empty cell for features */}
            {plans.map((plan) => (
              <Th
                key={plan.name}
                bg={plan.name === highlightPlan ? highlightBg : undefined}
              >
                {plan.name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {/* Price Row */}
          <Tr>
            <Td>Price</Td>
            {plans.map((plan) => (
              <Td
                key={plan.name}
                bg={plan.name === highlightPlan ? highlightBg : undefined}
              >
                {isYearly ? plan.priceYearly : plan.priceMonthly}
              </Td>
            ))}
          </Tr>

          {/* Buttons Row */}
          <Tr>
            <Td></Td>
            {plans.map((plan) => (
              <Td
                key={plan.name}
                bg={plan.name === highlightPlan ? highlightBg : undefined}
              >
                <Button
                  colorScheme={plan.name === highlightPlan ? "gray" : "blue"}
                  isDisabled={plan.name === highlightPlan}
                >
                  {plan.name === highlightPlan
                    ? "Current plan"
                    : `Upgrade to ${plan.name}`}
                </Button>
              </Td>
            ))}
          </Tr>

          {/* Dynamic Feature Rows */}
          {Object.keys(plans[0].features).map((feature) => (
            <Tr key={feature}>
              <Td>{feature}</Td>
              {plans.map((plan) => (
                <Td
                  key={plan.name}
                  bg={plan.name === highlightPlan ? highlightBg : undefined}
                >
                  {plan.features[feature]}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export const PlansDialog = ({
  highlightPlan,
  plans,
  children,
  isOpen,
  onClose,
}: PropsWithChildren<{
  highlightPlan: string;
  plans: Plan[];
  isOpen: boolean;
  onClose: () => void;
}>) => {
  return (
    <>
      <Modal onClose={onClose} size={"6xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <PricingTable highlightPlan={highlightPlan} plans={plans} />
          </ModalBody>
        </ModalContent>
      </Modal>
      {children}
    </>
  );
};
