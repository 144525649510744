import { Box, Flex } from "@chakra-ui/react";
import { CaretRight } from "phosphor-react";
import { Project } from "../../../../../Api/Resources/Projects/ProjectsApiTypes";
import ResponsiveContainer from "../../../../../UIKit/ResponsiveContainer/ResponsiveContainer";
import styles from "./ProjectsListItem.module.scss";
import { Item } from "../../../../../UIKit/ListItem/ListItem";

export const ProjectsListItem = ({
  item,
  onClick,
}: {
  item: Item;
  onClick?: (id: string) => void;
}) => {
  const project: Project = item.data;

  return (
    <Flex
      onClick={() => onClick && project.id && onClick(project.id)}
      className={styles.listItem}
    >
      <ResponsiveContainer>
        <Flex alignItems={"center"}>
          <Flex>
            <Box
              className={styles.indicator}
              sx={{ backgroundColor: project.color }}
            />
          </Flex>
          <Flex className={styles.container}>
            <Flex className={styles.content}>
              <Flex className={styles.name}>{item.label}</Flex>
              <Flex className={styles.description}>{item.description}</Flex>
              <Flex className={styles.color}>{project.color}</Flex>
            </Flex>
          </Flex>
          <CaretRight weight="bold" size={24} />
        </Flex>
      </ResponsiveContainer>
    </Flex>
  );
};
