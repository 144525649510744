export function groupBy<T, K extends string | number | symbol | undefined>(
  array: T[],
  getKey: (item: T) => K,
  defaultKey: string
): Record<string, T[]> {
  return array.reduce((result, item) => {
    const key = (getKey(item) ?? defaultKey).toString(); // Convert the key to string
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {} as Record<string, T[]>);
}
