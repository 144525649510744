import { Flex, SimpleGrid, Heading, Text } from "@chakra-ui/react";
import { Card } from "../../../../UIKit/Card/Card";
import useAnalysisApi from "../../../../Api/Resources/Analysis/AnalysisApi";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import Loading from "../../../../UIKit/Loading/Loading";
import { formatTime } from "../../../../Utilities/TimeUtility";

const UserInsightsTab = () => {
  const { personId: userId } = useParams();

  const analysisApi = useAnalysisApi();

  const { data: summary, isFetching: isFetchingSummary } = useQuery(
    ["userAnalysis", userId],
    () => {
      if (userId) {
        return analysisApi.summary({
          userId,
          startDate: format(new Date(), "yyyy-MM-dd"),
        });
      }

      return;
    }
  );

  if (isFetchingSummary) {
    return <Loading />;
  }

  return (
    <Flex>
      <SimpleGrid width={"100%"} columns={[1, 1, 2, 3]} spacing={4}>
        <Card>
          <Heading fontWeight={"medium"} size="md" as="h3">
            This Week
          </Heading>
          <Text fontWeight={"bold"} fontSize={36}>
            {formatTime(summary?.thisWeek.totalTime ?? 0)} hours
          </Text>
        </Card>
        <Card>
          <Heading fontWeight={"medium"} size="md" as="h3">
            Last Week
          </Heading>
          <Text fontWeight={"bold"} fontSize={36}>
            {formatTime(summary?.lastWeek.totalTime ?? 0)} hours
          </Text>
        </Card>
      </SimpleGrid>
    </Flex>
  );
};

export default UserInsightsTab;
