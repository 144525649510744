import { Flex, Spinner, Text } from "@chakra-ui/react";
import { IconProps } from "phosphor-react";
import styles from "./Placeholder.module.scss";

const Placeholder = ({
  message,
  isLoading = false,
  icon,
}: {
  icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  message: string;
  isLoading?: boolean;
}) => {
  const Icon = icon;

  if (isLoading) {
    return (
      <Flex className={styles.container}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex className={styles.container}>
      <Icon weight="thin" size={100} />
      <Text fontSize={"20px"} marginTop={"8px"}>
        {message}
      </Text>
    </Flex>
  );
};

export default Placeholder;
