import { Td } from "@chakra-ui/react";
import styles from "./Table.module.scss";
import { RenderRowOptions, TableColumn, TableRow } from "./Table";
import { NavLink } from "react-router-dom";

export type LinkRow = {
  link: string;
  value: string;
};

export const LinkTableRow = ({
  column,
  columnIndex,
  row,
  rowIndex,
  totalRows,
}: RenderRowOptions) => {
  const { value, link } = row[column.accessor] as LinkRow;

  return (
    <Td
      key={`${rowIndex}-${columnIndex}`}
      borderBottom={rowIndex === totalRows - 1 ? 0 : undefined}
      isNumeric={column.isNumeric}
      className={`${column.isLink ? styles.link : ""}`}
    >
      <NavLink style={{ color: "blue" }} to={link}>
        {value}
      </NavLink>
    </Td>
  );
};
