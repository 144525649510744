import useMethods from "../../useMethods";
import {
  ApproveRequest,
  Entry,
  EntryMetaData,
  EntryRequest,
  UpdateEntryTimerRequest,
} from "./EntriesApiTypes";

export type EntryQueryStringOptions = {
  startDate?: string;
  endDate?: string;
  organisationId?: string;
  projectId?: string;
  teamId?: string;
  projects?: string[];
  categories?: string[];
  members?: string[];
  approved?: boolean;
};

const useEntriesApi = () => {
  const methods = useMethods();

  const createQueryString = (options: EntryQueryStringOptions) => {
    const { startDate, endDate } = options;
    const dates =
      startDate && endDate
        ? `startDate=${startDate}&endDate=${endDate}`
        : undefined;
    const projects =
      (options.projects ?? []).length > 0
        ? `projects=${(options.projects ?? []).join(",")}`
        : undefined;
    const categories =
      (options.categories ?? []).length > 0
        ? `categories=${(options.categories ?? []).join(",")}`
        : undefined;
    const members =
      (options.members ?? []).length > 0
        ? `members=${(options.members ?? []).join(",")}`
        : undefined;
    const approved =
      options.approved !== undefined
        ? `approved=${options.approved}`
        : undefined;

    let queryString = "";
    if (dates || projects || categories || members || approved) {
      queryString = `?${[dates, projects, categories, members, approved]
        .filter((x) => x)
        .join("&")}`;
    }

    return queryString;
  };

  const listForUser = async (
    options: {
      userId: string;
    } & EntryQueryStringOptions
  ): Promise<Entry[]> => {
    const { userId } = options;
    const queryString = createQueryString(options);

    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}/entries${queryString}`
    );
    return result.data;
  };

  const listForProject = async (
    options: EntryQueryStringOptions
  ): Promise<{
    count: number;
    data: Entry[];
  }> => {
    const { organisationId, projectId } = options;
    const queryString = createQueryString(options);

    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/projects/${projectId}/entries${queryString}`
    );

    return result.data;
  };

  const listForTeam = async (
    options: EntryQueryStringOptions
  ): Promise<{
    count: number;
    data: Entry[];
  }> => {
    const { teamId } = options;
    const queryString = createQueryString(options);

    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/teams/${teamId}/entries${queryString}`
    );

    return result.data;
  };

  const getMetaData = async (
    startDate: string,
    endDate: string
  ): Promise<EntryMetaData> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/entries/metadata?startDate=${startDate}&endDate=${endDate}`
    );
    return result.data;
  };

  const create = async (entry: EntryRequest): Promise<Entry> => {
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/entries`,
      entry
    );
    return result.data;
  };

  const update = async (entry: EntryRequest): Promise<Entry> => {
    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/entries/${entry.id}`,
      entry
    );
    return result.data;
  };

  const approve = async (approveRequest: ApproveRequest): Promise<Entry> => {
    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/entries/${approveRequest.entryId}/approve`,
      { approved: approveRequest.approved }
    );
    return result.data;
  };

  const deleteOne = async (entryId: string): Promise<Entry> => {
    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/entries/${entryId}`
    );
    return result.data;
  };

  const startTimer = async (entry: UpdateEntryTimerRequest): Promise<Entry> => {
    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/entries/${entry.id}/start`,
      entry
    );
    return result.data;
  };

  const stopTimer = async (entry: UpdateEntryTimerRequest): Promise<Entry> => {
    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/entries/${entry.id}/stop`,
      entry
    );
    return result.data;
  };

  return {
    getMetaData,
    create,
    update,
    startTimer,
    stopTimer,
    deleteOne,
    listForProject,
    listForTeam,
    approve,
    listForUser,
  };
};

export default useEntriesApi;
