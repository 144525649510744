import create from "zustand";
import { devtools } from "zustand/middleware";

export interface EntryState {
  additionalTime: number;
  setAdditionalTime: (additionalTime: number) => void;
}

export const useEntryStore = create<EntryState>()(
  devtools(
    (set) => ({
      additionalTime: 0,
      setAdditionalTime: (additionalTime) => set(() => ({ additionalTime })),
    }),
    {
      name: "entry-state",
    }
  )
);
