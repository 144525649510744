import { Flex } from "@chakra-ui/react";
import Loading from "../Loading/Loading";
import ResponsiveContainer from "../ResponsiveContainer/ResponsiveContainer";
import styles from "./Page.module.scss";

const Page = ({
  children,
  isResponsive = true,
  isLoading = false,
}: {
  children: JSX.Element[] | JSX.Element | string;
  isResponsive?: boolean;
  isLoading?: boolean;
}) => {
  if (isResponsive) {
    return (
      <Flex className={styles.page}>
        {isLoading ? (
          <Loading />
        ) : (
          <ResponsiveContainer>{children}</ResponsiveContainer>
        )}
      </Flex>
    );
  } else {
    if (isLoading) {
      return <Loading />;
    }

    return <Flex className={styles.page}>{children}</Flex>;
  }
};

export default Page;
