import { format } from "date-fns";
import { Entry } from "./EntriesApiTypes";
import EntriesApi from "./EntriesApi";
import { useMutation, useQueryClient } from "react-query";
import { getSecondsFromTime } from "../../../Utilities/TimeUtility";

const useEntryMutations = () => {
  const entriesApi = EntriesApi();
  const queryClient = useQueryClient();

  const updateEntry = useMutation({
    mutationFn: (options: {
      startDate: Date;
      time: string;
      entry: Entry;
      projectId?: string;
      categoryId?: string;
      description?: string;
    }) => {
      const { description, time, entry, projectId, categoryId } = options;
      const { project, category, ...remaining } = entry;

      return entriesApi.update({
        ...remaining,
        projectId,
        categoryId,
        description,
        time: getSecondsFromTime(time),
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["entries"] });
      queryClient.invalidateQueries({ queryKey: ["metadata"] });
      queryClient.invalidateQueries({ queryKey: ["entries"] });
    },
  });

  const approveEntry = useMutation({
    mutationFn: (options: {
      approved: boolean;
      entry: Entry;
      projectId: string;
      organisationId: string;
    }) => {
      const { entry, approved } = options;

      return entriesApi.approve({
        entryId: entry.id!,
        approved,
      });
    },
    onSuccess: (_, variables) => {
      const { organisationId, projectId } = variables;
      console.log("Project ID", projectId, organisationId);

      queryClient.invalidateQueries({
        queryKey: ["awaitingApprovalEntries", projectId, organisationId],
      });
    },
  });

  const createEntry = useMutation({
    mutationFn: (options: {
      startDate: Date;
      time: string;
      order: number;
      description?: string;
      projectId?: string;
      categoryId?: string;
    }) => {
      const { description, startDate, time, projectId, categoryId, order } =
        options;

      return entriesApi.create({
        projectId,
        categoryId,
        description,
        date: format(startDate, "yyyy-MM-dd"),
        time: getSecondsFromTime(time),
        order,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["entries"] });
      queryClient.invalidateQueries({ queryKey: ["metadata"] });
    },
  });

  const deleteEntry = useMutation({
    mutationFn: (options: { entryId: string }) => {
      const { entryId } = options;
      return entriesApi.deleteOne(entryId);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["entries"] });
      queryClient.invalidateQueries({ queryKey: ["metadata"] });
    },
  });

  return {
    createEntry,
    updateEntry,
    deleteEntry,
    approveEntry,
  };
};

export default useEntryMutations;
