import { Category } from "../Categories/CategoriesApiTypes";
import { Organisation } from "../Organisations/OrganisationsApiTypes";
import { Team } from "../Teams/TeamsApiTypes";
import { User } from "../Users/UsersApiTypes";

export type Project = {
  id?: string;
  name: string;
  description?: string;
  color?: string;
  organisation?: Organisation;
  team?: Team;
  requireApproval?: boolean;
};

export enum ChargeRateType {
  Hour = "hour",
  Day = "day",
}

export type ChargeRate = {
  id?: string;
  value: string;
  projectId?: string;
  categoryId?: string;
  type: ChargeRateType;
  userId?: string;
  user?: User;
  displayName?: string;
};

export type CreateProjectRequest = {
  id?: string;
  name: string;
  description?: string;
  color?: string;
  organisationId?: string;
  teamId?: string;
  requireApproval?: boolean;
};

export type UpdateProjectRequest = Partial<CreateProjectRequest>;

export type CreateChargeRateRequest = {
  rates: {
    value: string;
    type: ChargeRateType;
    userId: string;
    projectId: string;
    isNew: boolean;
  };
};

export type UpdateChargeRateRequest = Partial<CreateChargeRateRequest>;
