import { Td, IconButton } from "@chakra-ui/react";
import { RenderRowOptions } from "./Table";

export const ButtonTableRow = ({
  columnIndex,
  rowIndex,
  onItemClicked,
  totalRows,
  icon,
}: RenderRowOptions & {
  icon: any;
  onItemClicked: (options: { rowIndex: number; columnIndex: number }) => void;
}) => {
  const Icon = icon;

  return (
    <Td isNumeric borderBottom={rowIndex === totalRows - 1 ? 0 : undefined}>
      <IconButton
        icon={<Icon size={24} />}
        onClick={() =>
          onItemClicked && onItemClicked({ rowIndex, columnIndex })
        }
        aria-label={""}
      />
    </Td>
  );
};
