import { Button, Flex } from "@chakra-ui/react";
import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import useBillingApi from "../../../../Api/Resources/Billing/BillingApi";
import { SubscriptionRecord } from "../../../../Api/Resources/Billing/BillingApiTypes";
import TitledPage from "../../../../Components/TitledPage";
import ResponsiveContainer from "../../../../UIKit/ResponsiveContainer/ResponsiveContainer";
import { CreditCard } from "phosphor-react";

const stripePromise = loadStripe(
  "pk_test_51MSfAxGuDDieecocQ2ZCjeH3TUf6FNdxxZP7joHzywoF2GINZkVa5MGv6tZTbbV83XHteum3UWc70Iuha9G5XMC700AkPH0MH9"
);

const Form = ({
  priceId,
  isLoading,
}: {
  priceId: string;
  isLoading: boolean;
}) => {
  // collect data from the user
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  const confirmPayment = async () => {
    if (!stripe) {
      throw new Error("Stripe not initialised");
    }

    const { error } = await stripe.confirmPayment({
      elements: elements!,
      confirmParams: {
        return_url: "http://localhost:3001/account/billing",
      },
    });

    if (error) {
      throw new Error(error.message);
    }
  };

  return (
    <TitledPage
      isResponsive={false}
      isLoading={isLoading}
      title="Checkout"
      breadcrumbs={[
        {
          label: "Account",
          link: "/account",
        },
        {
          label: "Plan & Billing",
          link: "/account/billing",
        },
      ]}
      action={{
        label: "Submit",
        icon: CreditCard,
        trigger: confirmPayment,
      }}
    >
      <ResponsiveContainer paddingTop={"12px"}>
        <div className="grid gap-4 m-auto">
          <PaymentElement />
        </div>
      </ResponsiveContainer>
    </TitledPage>
  );
};

const CheckoutPage = () => {
  const { createSubscription } = useBillingApi();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const priceId = query.get("priceId");

  const { data: subscription, isFetching } = useQuery(
    ["subscription", priceId],
    () => {
      if (priceId) {
        return createSubscription(priceId);
      }

      return;
    }
  );

  const options = {
    // passing the client secret obtained from the server
    clientSecret: subscription?.clientSecret,
  };

  if (subscription?.clientSecret) {
    return (
      <Elements options={options} stripe={stripePromise}>
        <Form priceId={priceId!} isLoading={!subscription?.clientSecret} />
      </Elements>
    );
  } else {
    return (
      <TitledPage
        isLoading={true}
        title="Checkout"
        breadcrumbs={[
          {
            label: "Account",
            link: "/account",
          },
          {
            label: "Plan & Billing",
            link: "/account/billing",
          },
        ]}
      />
    );
  }
};

export default CheckoutPage;
