import { Container, ContainerProps, Flex } from "@chakra-ui/react";

const ResponsiveContainer = ({
  children,
  isResponsive = true,
  ...props
}: { children: any; isResponsive?: boolean } & ContainerProps) => {
  return (
    <Container {...props} maxW="6xl">
      <Flex flexDirection={"column"} {...props}>
        {children}
      </Flex>
    </Container>
  );
};

export default ResponsiveContainer;
