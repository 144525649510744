import { Box, Flex } from "@chakra-ui/react";
import { CaretRight, IconProps } from "phosphor-react";
import ResponsiveContainer from "../ResponsiveContainer/ResponsiveContainer";
import styles from "./ListItem.module.scss";

export type Item = {
  id: string;
  type: ItemType;
  label: string;
  description?: string;
  icon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  isDisabled?: boolean;
  data?: any;
};

export enum ItemType {
  Header = "header",
  Item = "item",
}

export const ListItemHeader = (props: { item: Item }) => {
  const { item } = props;
  return (
    <Flex className={`${styles.header}`}>
      <ResponsiveContainer
        marginTop={"16px"}
        marginBottom={"4px"}
        justifyContent={"center"}
      >
        {item.label}
      </ResponsiveContainer>
    </Flex>
  );
};

export const ListItem = ({
  item,
  onClick,
  control,
  isClickable = true,
}: {
  item: Item;
  onClick?: () => void;
  control?: any;
  isClickable?: boolean;
}) => {
  const Icon = item.icon;

  switch (item.type) {
    case ItemType.Header: {
      return <ListItemHeader item={item} />;
    }
    case ItemType.Item: {
      return (
        <Flex
          onClick={onClick}
          className={`${styles.listItem} ${isClickable ? styles.enabled : ``} ${
            item.isDisabled ? styles.disabled : ""
          }`}
        >
          <ResponsiveContainer>
            <Flex alignItems={"center"}>
              {Icon && <Icon size={32} />}
              <Flex
                className={`${styles.container} ${!Icon ? styles.noIcon : ""}`}
              >
                <Flex
                  className={`${styles.content} ${
                    isClickable ? styles.enabled : ``
                  }`}
                >
                  <Flex className={styles.name}>{item.label}</Flex>
                  <Flex className={styles.description}>{item.description}</Flex>
                </Flex>
              </Flex>
              {control ? control : <CaretRight weight="bold" size={24} />}
            </Flex>
          </ResponsiveContainer>
        </Flex>
      );
    }
  }
};
