import { Flex } from "@chakra-ui/react";
import { PauseCircle, PlayCircle } from "phosphor-react";
import shallow from "zustand/shallow";
import { useSettingsStore } from "../../Store/SettingsStore";
import { formatTime } from "../../Utilities/TimeUtility";
import styles from "./Timer.module.scss";

const Timer = ({
  time,
  isActive,
  onPause,
  onPlay,
  color,
}: {
  time: number;
  isActive: boolean;
  onPause: (time: number) => void;
  onPlay: () => void;
  color?: string;
}) => {
  const { showSeconds } = useSettingsStore(
    (state) => ({
      showSeconds: state.showSeconds,
    }),
    shallow
  );

  return (
    <Flex
      onClick={() => {
        isActive ? onPause(time) : onPlay();
      }}
      className={styles.timer}
    >
      <Flex className={styles.controls}>
        <Flex
          className={styles.time}
          sx={{
            color: isActive
              ? color
                ? color
                : "var(--chakra-colors-yellow400)"
              : "var(--chakra-colors-text)",
          }}
        >
          {formatTime(time, showSeconds)}
        </Flex>
        {isActive ? (
          <PauseCircle
            color={color ? color : "var(--chakra-colors-yellow400)"}
            size={28}
          />
        ) : (
          <PlayCircle onClick={onPlay} size={28} />
        )}
      </Flex>
    </Flex>
  );
};

export default Timer;
