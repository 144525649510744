import { Button } from "@chakra-ui/react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import useBillingApi from "../../../../Api/Resources/Billing/BillingApi";
import TitledPage from "../../../../Components/TitledPage";
import ResponsiveContainer from "../../../../UIKit/ResponsiveContainer/ResponsiveContainer";

const CancelSubscription = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const subscriptionId = query.get("subscriptionId");
  const { cancelSubscription } = useBillingApi();

  return (
    <TitledPage title="Cancel Subscription">
      <ResponsiveContainer>
        {!subscriptionId ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>No valid subscription.</AlertTitle>
            <AlertDescription>
              No subscription ID. 
            </AlertDescription>
          </Alert>
        ) : (
          <Flex>
            Are you sure you want to cancel?
            <Button onClick={() => cancelSubscription(subscriptionId)}>
              Cancel Subscription
            </Button>
          </Flex>
        )}
      </ResponsiveContainer>
    </TitledPage>
  );
};

export default CancelSubscription;
