import { ClockAfternoon } from "phosphor-react";
import TitledPage from "../../../../Components/TitledPage";
import ListContainer from "../../../../UIKit/List/List";
import { ItemType, ListItem } from "../../../../UIKit/ListItem/ListItem";
import { Switch } from "@chakra-ui/react";
import { useSettingsStore } from "../../../../Store/SettingsStore";
import shallow from "zustand/shallow";

const SettingsPage = () => {
  const { showSeconds, setShowSeconds } = useSettingsStore(
    (state) => ({
      showSeconds: state.showSeconds,
      setShowSeconds: state.setShowSeconds,
    }),
    shallow
  );

  return (
    <TitledPage
      breadcrumbs={[
        {
          label: "Account",
          link: "/account",
        },
      ]}
      isResponsive={false}
      title="Settings"
    >
      <ListContainer>
        <ListItem
          isClickable={false}
          item={{
            id: "app_settings",
            type: ItemType.Header,
            label: "App Settings",
          }}
        />
        <ListItem
          isClickable={false}
          item={{
            id: "show_seconds",
            type: ItemType.Item,
            label: "Show Seconds",
            description: "Show seconds when timing entries.",
            icon: ClockAfternoon,
          }}
          control={
            <Switch
              isChecked={showSeconds}
              onChange={() => setShowSeconds(!showSeconds)}
            />
          }
        />
      </ListContainer>
    </TitledPage>
  );
};

export default SettingsPage;
