import create from "zustand";
import { devtools } from "zustand/middleware";

export enum Platform {
  Web = "web",
  Mobile = "mobile",
  Menubar = "menubar",
  Desktop = "desktop",
}

export interface PlatformState {
  platform: Platform;
  setPlatform: (platform: Platform) => void;
}

export const usePlatformStore = create<PlatformState>()(
  devtools(
    (set) => ({
      setPlatform: (platform) => set(() => ({ platform })),
      platform: Platform.Web,
    }),
    {
      name: "platform-state",
    }
  )
);
