export const formatTime = (
  totalSeconds: number,
  showSeconds: boolean = false,
  showSign: boolean = false
) => {
  const sign =
    totalSeconds === 0 || !showSign ? "" : totalSeconds > 0 ? "+" : "-";

  if (totalSeconds) {
    if (showSeconds) {
      return `${sign}${new Date(totalSeconds * 1000)
        .toISOString()
        .slice(11, 19)}`;
    }

    return `${sign}${new Date(totalSeconds * 1000)
      .toISOString()
      .slice(11, 16)}`;
  } else {
    return "00:00";
  }
};

/**
 * Get the seconds from the time string
 * @param time - time string.
 * @returns - the seconds of the time string.
 */
export const getSecondsFromTime = (time: string) => {
  const split = time.split(":");

  const hours = Number(split[0]);
  const minutes = Number(split[1]);
  let seconds = 0;

  if (split.length === 3) {
    const seconds = Number(split[2]);
  }

  return hours * 3600 + minutes * 60 + seconds;
};
