import { ReactElement } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import { Buildings } from "phosphor-react";
import {
  ChartBar,
  CloudCheck,
  Export,
  Kanban,
  PaintRoller,
  Ticket,
} from "phosphor-react";

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack align={"center"}>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={useColorModeValue("orange.200", "orange.900")}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text align={"center"} lineHeight={"28px"} color={"gray.400"}>
        {text}
      </Text>
    </Stack>
  );
};

export default function HighlightsSection() {
  return (
    <Box p={4}>
      <Heading textAlign={"center"} marginBottom={20}>
        Our Features
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon color={"orange.500"} as={Ticket} w={10} h={10} />}
          title={"Manage Entries"}
          text={
            "Quickly and easily add entries, with the click of a button. A streamlined UI makes it simple to start and stop entries timers, and update entry details"
          }
        />
        <Feature
          icon={<Icon color={"orange.500"} as={Kanban} w={10} h={10} />}
          title={"Projects & Categories"}
          text={
            "Assign entries to projects, and project categories. This makes it easy to perform billing, and to keep track of the status of ongoing project work."
          }
        />
        <Feature
          icon={<Icon color={"orange.500"} as={ChartBar} w={10} h={10} />}
          title={"Analysis"}
          text={
            "Analytics tools enable you to see where time is being spent. View a breakdown of time within categories and projects."
          }
        />
        <Feature
          icon={<Icon color={"orange.500"} as={Export} w={10} h={10} />}
          title={"Export"}
          text={
            "Easy one-step export of entry data for particular time-frames, projects, and categories."
          }
        />
        <Feature
          icon={<Icon color={"orange.500"} as={CloudCheck} w={10} h={10} />}
          title={"Sync"}
          text={
            "Data is synced across devices, so you can stop and start timers from any device, even when you're on-the-go."
          }
        />
        <Feature
          icon={<Icon color={"orange.500"} as={PaintRoller} w={10} h={10} />}
          title={"Themes"}
          text={
            "Color coded projects, and light and dark themes mean you can work all day long."
          }
        />
      </SimpleGrid>
    </Box>
  );
}
