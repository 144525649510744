import create from "zustand";
import { devtools, persist } from "zustand/middleware";

export interface SettingsState {
  showSeconds?: boolean;
  setShowSeconds: (showSeconds: boolean) => void;
}

export const useSettingsStore = create<SettingsState>()(
  persist(
    (set) => ({
      setShowSeconds: (showSeconds) => set(() => ({ showSeconds })),
    }),
    {
      name: "settings-state",
    }
  )
);
