import { Box, Flex } from "@chakra-ui/react";
import shallow from "zustand/shallow";
import { Entry } from "../../../../../Api/Resources/Entries/EntriesApiTypes";
import { useEntryStore } from "../../../../../Store/EntryStore";
import ResponsiveContainer from "../../../../../UIKit/ResponsiveContainer/ResponsiveContainer";
import Timer from "../../../../../UIKit/Timer/Timer";
import styles from "./EntriesListItem.module.scss";

export const EntriesListItem = ({
  item,
  onClick,
  onPause,
  onPlay,
  isTiming = false,
  color,
}: {
  item: Entry;
  onClick?: () => void;
  onPause: () => void;
  onPlay: () => void;
  isTiming?: boolean;
  color?: string;
}) => {
  const { setAdditionalTime, additionalTime } = useEntryStore(
    (state) => ({
      additionalTime: state.additionalTime,
      setAdditionalTime: state.setAdditionalTime,
    }),
    shallow
  );

  const _onPause = () => {
    setAdditionalTime(0);
    onPause();
  };

  return (
    <Flex className={`${styles.listItem}`}>
      <ResponsiveContainer>
        <Flex className={styles.container}>
          <Box className={styles.indicator} sx={{ backgroundColor: color }} />

          <Flex onClick={onClick} className={styles.content}>
            <Flex className={styles.projectName} sx={{ color }}>
              {item.project?.name ?? "No Project"}
            </Flex>
            <Flex className={styles.categoryName}>{item.category?.name}</Flex>
            <Flex className={styles.description}>
              {item?.description ?? "Untitled Entry"}
            </Flex>
          </Flex>
          <Timer
            onPause={_onPause}
            onPlay={onPlay}
            isActive={isTiming}
            time={isTiming ? item.time + additionalTime : item.time}
            color={color}
          />
        </Flex>
      </ResponsiveContainer>
    </Flex>
  );
};
