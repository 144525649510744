import { Flex, FlexProps } from "@chakra-ui/react";
import { Item, ItemType, ListItemHeader } from "../ListItem/ListItem";
import { ProjectsListItem } from "../../Pages/Projects/List/Components/ProjectsListItem/ProjectsListItem";

const ListContainer = (
  props: FlexProps & {
    children: JSX.Element | JSX.Element[];
  }
) => {
  return (
    <Flex {...props} flexDirection={"column"}>
      {props.children}
    </Flex>
  );
};

export type Group = {
  id: string;
  title: string;
  items: Item[];
};

export const ListGroup = ({
  groups,
  listItem,
  onClick,
}: {
  groups: Group[];
  listItem: any;
  onClick: (id: string) => void;
}) => {
  const ListItem = listItem;

  return (
    <>
      {groups.map((group) => {
        return (
          <>
            <ListItemHeader
              item={{
                type: ItemType.Header,
                label: group.title,
                id: group.id,
              }}
            />
            {group.items.map((item) => {
              return <ListItem onClick={onClick} item={item} />;
            })}
          </>
        );
      })}
    </>
  );
};

export default ListContainer;
