import { Flex, Spinner } from "@chakra-ui/react";

const Loading = () => {
  return (
    <Flex
      sx={{ height: "300px", justifyContent: "center", alignItems: "center" }}
    >
      <Spinner size={"xl"} />
    </Flex>
  );
};

export default Loading;
