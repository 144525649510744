import { Button, useDisclosure, VStack, HStack } from "@chakra-ui/react";
import { CreditCard, User } from "phosphor-react";
import { useAuthStore } from "../../../../Store/AuthStore";
import shallow from "zustand/shallow";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import { PlansDialog } from "../../../../UIKit/PlansDialog/PlansDialog";
import TitledPage from "../../../../Components/TitledPage";
import { usePermissions } from "../../../../Store/PermissionStore";

const pricingPlans = [
  {
    name: "Free",
    priceMonthly: "£11/seat/mo",
    priceYearly: "£30/seat/mo",
    features: {
      Entries: "25",
      ["Charge Rates"]: "-",
      Customization: "-",
    },
  },
  {
    name: "Professional",
    priceMonthly: "£40/seat/mo",
    priceYearly: "£80/seat/mo",
    features: {
      Entries: "Unlimited",
      ["Charge Rates"]: "✓",
      Customization: "✓",
    },
  },
];

const ViewPersonalDetails = () => {
  const { authTokens } = useAuthStore(
    (state) => ({ authTokens: state.authTokens }),
    shallow
  );

  const getPermissions = usePermissions();
  const userId = authTokens?.userId;

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <TitledPage
      isLoading={false}
      title={"Personal Details"}
      breadcrumbs={[
        {
          label: "Account",
          link: "/account",
        },
        {
          label: "Personal Details",
          link: "/account/organisations",
        },
      ]}
      action={[]}
      placeholderIcon={User}
      placeholderMessage={"No organisations."}
      showPlaceholder={false}
    >
      <VStack
        marginTop={"16px"}
        marginBottom={"64px"}
        spacing={4}
        flexDirection={"column"}
      >
        <Card>
          <CardHeader
            isStandalone={true}
            title="Plan & Billing"
            subtitle={"Your plan and billing information."}
            action={
              <HStack>
                {getPermissions("change_user_plan", userId!) && (
                  <PlansDialog
                    highlightPlan={"Team"}
                    plans={pricingPlans}
                    isOpen={isOpen}
                    onClose={onClose}
                  >
                    <Button
                      leftIcon={<CreditCard weight="bold" />}
                      variant={"outline"}
                      colorScheme={"blue"}
                      onClick={onOpen}
                    >
                      Change Plan
                    </Button>
                  </PlansDialog>
                )}
              </HStack>
            }
          />
        </Card>
      </VStack>
    </TitledPage>
  );
};

export default ViewPersonalDetails;
