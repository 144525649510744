import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Flex } from "@chakra-ui/react";
import { GraphData } from "../../Api/Resources/Analysis/AnalysisApiTypes";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props: GraphData) => {
  console.log("Props", props);
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      height={"500px"}
      width={"100%"}
    >
      <Doughnut data={props} />
    </Flex>
  );
};

export default DoughnutChart;
