import {
  Avatar,
  AvatarGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  SimpleGrid,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import { CheckCircle, CircleWavy, Export, Placeholder } from "phosphor-react";
import { RangeDatePicker } from "../../../../UIKit/DatePicker/RangeDatePicker";
import CustomTable from "../../../../UIKit/Table/Table";
import { format, parseISO, startOfDay } from "date-fns";
import { formatTime } from "../../../../Utilities/TimeUtility";
import DoughnutChart from "../../../../UIKit/Graphs/DoghnutChart";
import { useMemo, useState } from "react";
import useAnalysisApi from "../../../../Api/Resources/Analysis/AnalysisApi";
import { MultiValue, PropsValue } from "react-select";
import { useParams } from "react-router-dom";
import useCategoriesApi from "../../../../Api/Resources/Categories/CategoriesApi";
import useProjectsApi from "../../../../Api/Resources/Projects/ProjectsApi";
import useEntriesApi from "../../../../Api/Resources/Entries/EntriesApi";
import { useQuery } from "react-query";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";

export interface EntriesTabProps {
  hasTeam?: boolean;
  subtitle?: string;
  selectedDates?: Date[];
  controls?: JSX.Element[] | JSX.Element;
}

const EntriesTab = (props: EntriesTabProps) => {
  const { hasTeam = false } = props;
  const [selectedDates, setSelectedDates] = useState<Date[]>();
  const [selectedFilterCategory, setSelectedFilterCategory] =
    useState<MultiValue<{ value: string; label: string }> | null>();
  const [selectedFilterMember, setSelectedFilterMember] = useState<MultiValue<{
    value: string;
    label: string;
  }> | null>();
  const { projectId } = useParams();
  const segments = [
    { label: "Table", value: "table" },
    { label: "Graph", value: "graph" },
  ];

  const [selectedApproval, setSelectedApproval] =
    useState<PropsValue<{ label: string; value: string }>>(null);

  const [selectedSegment, setSelectedSegment] = useState<string>(
    segments[0].value
  );

  const { data: project, isFetching: isFetchingProject } = useQuery(
    ["project", projectId],
    () => {
      if (projectId) {
        return projectsApi.get(projectId);
      }

      return;
    }
  );

  const analysisApi = useAnalysisApi();
  const categoriesApi = useCategoriesApi();
  const projectsApi = useProjectsApi();
  const entriesApi = useEntriesApi();

  const organisationId = useMemo(() => {
    return project?.organisation?.id;
  }, [project]);

  const onExport = () => {
    let startDate: string | undefined;
    let endDate: string | undefined;

    if (selectedDates?.length === 2) {
      startDate = format(startOfDay(selectedDates[0]), "yyyy-MM-dd");
      endDate = format(startOfDay(selectedDates[1]), "yyyy-MM-dd");
    }

    return analysisApi.exportData({
      startDate,
      endDate,
      categories:
        selectedFilterCategory?.map((category) => category.value) ?? [],
      projects: [projectId!],
      members: selectedFilterMember?.map((member) => member.value) ?? [],
    });
  };

  const { data: entries, isFetching: isFetchingEntries } = useQuery(
    [
      "entries",
      projectId,
      organisationId,
      selectedDates,
      selectedFilterCategory,
      selectedFilterMember,
    ],
    () => {
      console.log("Fetching entries...", organisationId, projectId);
      if (projectId) {
        let startDate: string | undefined;
        let endDate: string | undefined;

        if (selectedDates?.length === 2) {
          startDate = format(startOfDay(selectedDates[0]), "yyyy-MM-dd");
          endDate = format(startOfDay(selectedDates[1]), "yyyy-MM-dd");
        }

        return entriesApi.listForProject({
          organisationId,
          projectId,
          startDate,
          endDate,
          categories:
            selectedFilterCategory?.map((category) => category.value) ?? [],
          members: selectedFilterMember?.map((member) => member.value) ?? [],
        });
      }

      return;
    }
  );

  const { data: graphData, isFetching: isFetchingGraph } = useQuery(
    [
      "graphEntries",
      projectId,
      organisationId,
      selectedDates,
      selectedFilterCategory,
      selectedFilterMember,
    ],
    () => {
      if (projectId) {
        let startDate: string | undefined;
        let endDate: string | undefined;

        if (selectedDates?.length === 2) {
          startDate = format(startOfDay(selectedDates[0]), "yyyy-MM-dd");
          endDate = format(startOfDay(selectedDates[1]), "yyyy-MM-dd");
        }

        return analysisApi.graph({
          projects: [projectId],
          startDate,
          endDate,
          categories:
            selectedFilterCategory?.map((category) => category.value) ?? [],
          members: selectedFilterMember?.map((member) => member.value) ?? [],
        });
      }

      return;
    }
  );

  const loadCategories = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    categoriesApi.list(projectId!, inputValue).then((categories) => {
      const result = categories.map((category) => {
        return {
          label: category.name ?? "Untitled Category",
          value: category.id!,
        };
      });

      callback(result);
    });
  };

  const loadMembers = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    projectsApi.listUsers(projectId!, inputValue).then((users) => {
      console.log("result is", users);
      const result = users.map((user) => {
        return {
          label: user.displayName ?? "Unknown User",
          value: user.id!,
        };
      });

      callback(result);
    });
  };

  const onSegmentChange = (value: string) => {
    setSelectedSegment(value);
  };

  const rows = useMemo(() => {
    return (
      entries?.data?.map((entry) => {
        return {
          description: entry.description,
          category: entry.category?.name,
          date: format(parseISO(entry.date), "yyyy-MM-dd"),
          time: formatTime(entry.time),
          users: (
            <AvatarGroup size="sm" max={2}>
              {entry?.users?.map((user) => {
                return <Avatar name={user.displayName} />;
              })}
            </AvatarGroup>
          ),
          approved: entry.approved ? <CheckCircle /> : <CircleWavy />,
        };
      }) ?? []
    );
  }, [entries]);

  const showApprovals = hasTeam && project?.requireApproval;

  let tableColumns = [
    { label: "Description", accessor: "description" },
    { label: "Category", accessor: "category" },
    { label: "Date", accessor: "date" },
    { label: "Time", accessor: "time" },
    { label: "Users", accessor: "users" },
  ];

  if (showApprovals) {
    tableColumns = [
      ...tableColumns,
      { label: "Approved", accessor: "approved" },
    ];
  }

  return (
    <VStack spacing={4}>
      <Card isPadded={false}>
        <CardHeader
          title="Entries"
          subtitle={props.subtitle}
          action={
            <HStack>
              <Button
                leftIcon={<Export weight="bold" />}
                variant={"outline"}
                colorScheme={"blue"}
                onClick={onExport}
              >
                Export
              </Button>
            </HStack>
          }
        />
        <HStack spacing={4} justifyContent={"space-evenly"}>
          <FormControl>
            <FormLabel htmlFor="dateRange">Date Range</FormLabel>
            <RangeDatePicker
              name="dateRange"
              usePortal
              selectedDates={selectedDates}
              onDateChange={setSelectedDates}
            />
          </FormControl>
          <Flex width={"100%"}>
            <FormControl>
              <FormLabel htmlFor="dateRange">Categories</FormLabel>
              <AsyncSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    borderColor: "var(--chakra-colors-chakra-border-color)",
                    backgroundColor: "var(--chakra-colors-background100)",
                  }),
                }}
                isClearable
                isMulti
                placeholder="Select Categories"
                value={selectedFilterCategory}
                onChange={(value) => {
                  if (value.length === 0) {
                    setSelectedFilterCategory(null);
                  }

                  setSelectedFilterCategory(value);
                }}
                loadOptions={loadCategories}
                defaultOptions={true}
              />
            </FormControl>
          </Flex>
          <Flex width={"100%"}>
            <FormControl>
              <FormLabel htmlFor="dateRange">Members</FormLabel>

              <AsyncSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "var(--chakra-colors-chakra-border-color)",
                    backgroundColor: "var(--chakra-colors-background100)",
                  }),
                }}
                isClearable
                isMulti
                placeholder="Select Members"
                value={selectedFilterMember}
                onChange={(value) => {
                  if (value.length === 0) {
                    setSelectedFilterMember(null);
                  }

                  setSelectedFilterMember(value);
                }}
                loadOptions={loadMembers}
                defaultOptions={true}
              />
            </FormControl>
          </Flex>
          {showApprovals && (
            <Flex width={"100%"}>
              <FormControl>
                <FormLabel htmlFor="dateRange">Approval</FormLabel>

                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "var(--chakra-colors-chakra-border-color)",
                      backgroundColor: "var(--chakra-colors-background100)",
                    }),
                  }}
                  options={[
                    {
                      label: "Approved",
                      value: "approved",
                    },
                    {
                      label: "Unapproved",
                      value: "unapproved",
                    },
                  ]}
                  isClearable={true}
                  placeholder="Select Approval"
                  value={selectedApproval}
                  onChange={(value) => {
                    if (!value) {
                      setSelectedApproval(null);
                    }

                    setSelectedApproval(value);
                  }}
                />
              </FormControl>
            </Flex>
          )}
        </HStack>
      </Card>
      <Card>
        {isFetchingEntries ? (
          <Spinner />
        ) : (
          <CustomTable
            placeholder={{
              label: "No entries",
              icon: Placeholder,
            }}
            renderRow={(options) => {
              return <StandardTableRow {...options} />;
            }}
            rows={rows}
            columns={tableColumns}
          />
        )}
      </Card>

      {selectedSegment === "graph" && (
        <SimpleGrid columns={[2]}>
          <Card width={"100%"}>
            {isFetchingEntries ? (
              <Spinner />
            ) : graphData ? (
              <DoughnutChart {...graphData} />
            ) : (
              <></>
            )}
          </Card>
          <Flex>
            <Card width={"100%"}>Time Spent on Categories</Card>
          </Flex>
        </SimpleGrid>
      )}
    </VStack>
  );
};

export default EntriesTab;
