export const tableTheme = {
  parts: ["th", "td", "tr"],
  baseStyle: {
    td: {
      borderBottom: "none",
    },
    // td: {
    //   borderBottom: "none",
    //   // "_last": {
    //   //   border: "0px solid",
    //   // },
    // },
  },
};
