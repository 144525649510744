import create from "zustand";
import useUsersApi from "../Api/Resources/Users/UsersApi";
import { useEffect } from "react";
import shallow from "zustand/shallow";

export type UserPermissions = {
  [key: string]: {
    description: string;
    value: {
      [key: string]: boolean;
    };
  };
};

export interface PermissionState {
  permissions: UserPermissions;
  setPermissions: (permissions: UserPermissions) => void;
  getPermission: (flag: string, resourceId: string) => boolean;
}

export const usePermissionStore = create<PermissionState>((set, get) => ({
  permissions: {},
  getPermission: (flag: string, resourceId: string) => {
    const permissions = get().permissions;
    return permissions[flag]?.value[resourceId] ?? false;
  },
  setPermissions: async (permissions: UserPermissions) => {
    set({ permissions });
  },
}));

export const usePermissions = () => {
  const { getPermission } = usePermissionStore(
    (state) => ({ getPermission: state.getPermission }),
    shallow
  );

  return getPermission;
};

export const useFetchPermissions = () => {
  const usersApi = useUsersApi();

  const { setPermissions } = usePermissionStore(
    (state) => ({ setPermissions: state.setPermissions }),
    shallow
  );

  // Fetch permissions on app load...
  useEffect(() => {
    const fetchPermissions = async () => {
      const permissions = await usersApi.getPermissions();
      setPermissions(permissions);
    };

    fetchPermissions();
  }, []);
};
