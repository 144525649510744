import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  Hide,
  useColorMode,
  HStack,
  Alert,
  AlertIcon,
  Collapse,
} from "@chakra-ui/react";
import BannerImage from "../../Assets/background.jpg";
import { ArrowLeft, GoogleLogo, SquaresFour } from "phosphor-react";
import { useEffect, useState } from "react";
import { useLocation, Navigate, NavLink } from "react-router-dom";
import shallow from "zustand/shallow";
import { useAuthStore } from "../../Store/AuthStore";
import IconLight from "../../Assets/icon.png";
import IconDark from "../../Assets/icon_dark.png";
import useUsersApi from "../../Api/Resources/Users/UsersApi";
import Separator from "../../UIKit/Separator/Separator";

export const LoginPage = ({ isSignUp }: { isSignUp?: boolean }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const accessToken = query.get("accessToken");
  const refreshToken = query.get("refreshToken");
  const platform = query.get("platform") ?? "web";
  const userId = query.get("userId");
  const usersApi = useUsersApi();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const [showVerificationMessage, setShowVerificationMessage] =
    useState<boolean>(false);

  const { setAuthTokens } = useAuthStore(
    (state) => ({ setAuthTokens: state.setAuthTokens }),
    shallow
  );

  useEffect(() => {
    if (accessToken && refreshToken && userId) {
      setAuthTokens({ accessToken, refreshToken, userId });
    }
  }, [refreshToken, accessToken, userId]);

  const { colorMode } = useColorMode();

  const signUp = async () => {
    // Sign up user...
    if (email && password && firstName && lastName) {
      const data = await usersApi.signUp({
        email,
        password,
        givenName: firstName,
        familyName: lastName,
      });
      setShowVerificationMessage(true);
    } else {
      setShowVerificationMessage(false);
      throw new Error("Details missing.");
    }
  };

  const signIn = async () => {
    // Sign in user...
    if (email && password) {
      const data = await usersApi.signIn(email, password);
      return setRedirectUrl(data.redirectUrl);
    } else {
      throw new Error("No email or password provided.");
    }
  };

  if (redirectUrl) {
    return <Navigate relative={"route"} to={redirectUrl} replace />;
  }

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      <Flex
        marginTop={"-42px"}
        p={8}
        flex={1}
        align={"center"}
        justify={"center"}
      >
        <Stack spacing={4} w={"full"} maxW={"lg"}>
          <Flex marginBottom={"42px"} justifyContent={"space-between"}>
            <Button
              to={"/"}
              as={NavLink}
              variant={"link"}
              width={"fit-content"}
              leftIcon={<ArrowLeft />}
            >
              Back Home
            </Button>
            <Flex alignItems={"center"}>
              {isSignUp ? `Already` : `Not`} a member?
              <Button
                as={NavLink}
                to={isSignUp ? "/login" : "/sign-up"}
                color={"orange"}
                marginLeft={"8px"}
                variant={"link"}
                width={"fit-content"}
              >
                {isSignUp ? `Sign in` : `Sign up`}
              </Button>
            </Flex>
          </Flex>

          <Heading marginBottom={"24px"} fontSize={"2xl"}>
            <img
              src={colorMode === "light" ? IconLight : IconDark}
              style={{ height: "42px", marginBottom: "16px" }}
            />
          </Heading>
          {isSignUp && (
            <HStack spacing={4}>
              <FormControl id="firstName">
                <FormLabel>First Name</FormLabel>
                <Input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="firstName"
                />
              </FormControl>
              <FormControl id="lastName">
                <FormLabel>Last Name</FormLabel>
                <Input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="lastName"
                />
              </FormControl>
            </HStack>
          )}
          <FormControl id="email">
            <FormLabel>Email address</FormLabel>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </FormControl>
          <Stack spacing={6}>
            <Stack
              direction={{ base: "column", sm: "row" }}
              align={"start"}
              justify={"space-between"}
            >
              <Checkbox>Remember me</Checkbox>
              <Link color={"blue.500"}>Forgot password?</Link>
            </Stack>
            <Button
              onClick={() => (isSignUp ? signUp() : signIn())}
              colorScheme={"blue"}
              variant={"solid"}
            >
              Sign {isSignUp ? "up" : "in"}
            </Button>

            <Collapse in={showVerificationMessage} animateOpacity>
              <Alert status="success">
                <AlertIcon />
                Please check your email to verify your account before signing
                in.
              </Alert>
            </Collapse>

            <Separator text={"or"} />

            <a
              href={`${process.env.REACT_APP_API_URL}/auth/google?platform=${platform}`}
            >
              <Button
                width={"100%"}
                leftIcon={<GoogleLogo size={18} weight="bold" />}
                variant="outline"
              >
                Sign {isSignUp ? "up" : "in"} with Google
              </Button>
            </a>

            <a
              style={{ marginTop: "8px" }}
              href={`${process.env.REACT_APP_API_URL}/auth/microsoft?platform=${platform}`}
            >
              <Button
                width={"100%"}
                leftIcon={<SquaresFour size={18} weight="fill" />}
                variant="outline"
              >
                Sign {isSignUp ? "up" : "in"} with Microsoft
              </Button>
            </a>
          </Stack>
        </Stack>
      </Flex>
      <Hide below="md">
        <Flex position={"relative"} flex={1}>
          <Image alt={"Login Image"} objectFit={"cover"} src={BannerImage} />
        </Flex>{" "}
      </Hide>
    </Stack>
  );
};

export default LoginPage;
