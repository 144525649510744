import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import { User } from "../Api/Resources/Users/UsersApiTypes";
import { HStack, Avatar, Flex } from "@chakra-ui/react";

export const UserHighlight = (props: PropsWithChildren<{ user: User }>) => {
  const { user } = props;
  return (
    <NavLink style={{ color: "blue" }} to={`/people/${props.user.id}`}>
      <HStack gap={3} alignItems={"center"}>
        <Avatar src={user.photoUrl} name={user.displayName ?? ""} />

        <Flex>{user.displayName}</Flex>
      </HStack>
    </NavLink>
  );
};
