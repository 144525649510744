import { Button, SimpleGrid } from "@chakra-ui/react";
import { CardElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import useBillingApi from "../../../../Api/Resources/Billing/BillingApi";
import TitledPage from "../../../../Components/TitledPage";
import PricingCard from "../../../../UIKit/PricingCard/PricingCard";
import ResponsiveContainer from "../../../../UIKit/ResponsiveContainer/ResponsiveContainer";
import { CreditCard } from "phosphor-react";

export interface Plan {
  planName: string;
  price: string;
  priceId?: string;
  points: string[];
}

const stripePromise = loadStripe(
  "pk_test_51MSfAxGuDDieecocQ2ZCjeH3TUf6FNdxxZP7joHzywoF2GINZkVa5MGv6tZTbbV83XHteum3UWc70Iuha9G5XMC700AkPH0MH9"
);

export const BillingPage = () => {
  const navigate = useNavigate();
  const { listIndividualPlans, getUserSubscription } = useBillingApi();

  const { data: _billing, isFetching: isFetchingBilling } = useQuery(
    ["individualPlans"],
    () => {
      return listIndividualPlans();
    }
  );

  const { data: subscription, isFetching: isFetchingSubscriptions } = useQuery(
    ["subscription"],
    () => {
      return getUserSubscription();
    }
  );

  const pricingCards: Plan[] = useMemo(() => {
    const defaultItems = [
      {
        planName: "Standard",
        price: "0",
        points: [
          "20 entries",
          "Category creation",
          "Sync between devices",
        ],
      },
    ];

    const items =
      _billing?.map((item) => {
        let points = [
          "Customize categories",
          "Advanced export & reporting",
          "Enhanced support",
        ];

        if (item.name.includes("Team")) {
          points = ["Team Management", "Charge Rates", "Priority support"];
        }
        return {
          planName: item.name,
          price: `${item.price.unit_amount / 100}`,
          priceId: item.price.id,
          points,
        };
      }) ?? [];

    return [...defaultItems, ...items];
  }, [_billing]);

  return (
    <TitledPage
      breadcrumbs={[
        {
          label: "Account",
          link: "/account",
        },
      ]}
      isResponsive={false}
      title={"Plan & Billing"}
      isLoading={isFetchingBilling}
      action={{
        label: " Manage Card Details",
        icon: CreditCard,
        trigger: () => {
          window.open(
            "https://billing.stripe.com/p/login/test_fZe18889z2f37Pq6oo",
            "_blank"
          );
        },
      }}
    >
      <ResponsiveContainer flexDirection={"row"}>
        <SimpleGrid columns={3} marginTop={4} spacing={6}>
          {pricingCards
            .sort((x, y) => Number(x.price) - Number(y.price))
            .map((plan) => {
              return (
                <PricingCard
                  key={plan.priceId}
                  onSelect={(plan) => {
                    if (plan.planName === "Standard") {
                      navigate(
                        `/account/billing/cancel?subscriptionId=${subscription?.id}`
                      );
                    } else {
                      navigate(
                        `/account/billing/checkout?priceId=${plan.priceId}`
                      );
                    }
                  }}
                  isSelected={subscription?.plan?.id === plan.priceId}
                  plan={plan}
                />
              );
            })}
        </SimpleGrid>
      </ResponsiveContainer>
    </TitledPage>
  );
};
